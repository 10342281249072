import { z } from 'zod';
import { likeToLearnMore } from 'domain/common/constants/like-to-learn-more.constants';

const { firstName, surname, email, message } = likeToLearnMore.contactUsForm.fields;

export const contactUsFormSchema = z.object({
  firstName: z.string().min(1, { message: firstName.error }),
  surname: z.string().min(1, { message: surname.error }),
  email: z.string().email({ message: email.error }),
  // phone: z.string().refine((value) => value === '' || /^(02|03|04|07|08)\d{8}$/.test(value), {
  //   message: 'The phone must be 10 digits and start with one of 02|03|04|07|08 with no spaces',
  // }),
  message: z.string().max(300, { message: message.error }),
});
