import { useMutation, MutationOptions } from 'react-query';
import axios, { AxiosResponse, AxiosError } from 'axios';
import { useAuthStore } from 'domain/state';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    'X-API-KEY': process.env.REACT_APP_API_KEY,
  },
});

export const validateEmailToken = async ({ token }: any): Promise<AxiosResponse<any>> => {
  return await api.post('/email_verifications/verify', { token });
};

export const verifyEmail = async ({ email }: any): Promise<AxiosResponse<any>> => {
  return await api.post('/email_verifications', { email });
};

export const signIn = async ({
  email,
  password,
}: {
  email: string;
  password: string;
}): Promise<AxiosResponse<any>> => {
  return await api.post('/sign_in', { email, password });
};
export const signOut = async ({ token }: any): Promise<any> => {
  return await api.delete('/sign_out', {
    headers: { Authorization: token },
  });
};

export const authenticateUser = async ({ token, data }: any): Promise<AxiosResponse<any>> => {
  return await api.post('/auth', data, {
    headers: { Authorization: token },
  });
};

export function useVerifyEmailQuery(options?: MutationOptions<AxiosResponse<any>, AxiosError, any>) {
  return useMutation(
    async (data) => {
      return await verifyEmail(data);
    },
    {
      onSuccess: (data) => {
        console.log(data);
      },
      ...options,
    }
  );
}

export function useValidateEmailTokenQuery(
  options?: MutationOptions<AxiosResponse<any>, AxiosError, any>
) {
  return useMutation(async (data) => await validateEmailToken(data), options);
}

export function useSignInMutation(
  options?: MutationOptions<AxiosResponse<any>, AxiosError, { email: string; password: string }>
) {
  return useMutation(signIn, options);
}
export const useSignOutQuery = () => {
  const setUser = useAuthStore((state) => state.setUser);
  const authToken = useAuthStore((state) => state?.user?.auth_token);

  return useMutation(() => signOut({ token: authToken }), {
    onSuccess: () => {
      setUser(null);
    },
    onError: () => {
      setUser(null);
    },
  });
};

export function useAuth(options?: MutationOptions<AxiosResponse<any>, AxiosError, any>) {
  return useMutation(
    async (data) => {
      return await authenticateUser(data);
    },
    {
      ...options,
    }
  );
}
