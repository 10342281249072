import { Text } from 'domain/common/components';

export type InfoCardProps = {
  icon: { src: any; alt?: string };
  title: string;
  description: string;
};
export function InfoCard({ icon, title, description }: InfoCardProps) {
  return (
    <div className="flex w-90 flex-col items-center px-12 pb-12 pt-10 text-center">
      <img className="w-14" {...icon} />
      <div className="mt-5 w-60 text-xl font-bold text-zembl-forest ">{title}</div>
      <Text intent="body" className="mt-7 w-full text-center text-zembl-ocean">
        {description}
      </Text>
    </div>
  );
}
