import {
  Button,
  ControlledInput,
  ControlledTextarea,
  Form,
  Modal,
  Text,
} from 'domain/common/components';
import { CheckCircleGreenIcon } from 'domain/assets';
import cn from 'classnames';
import { likeToLearnMore } from 'domain/common/constants/like-to-learn-more.constants';
import { useContactUs } from './use-contact-us.hook';

type MessageConfirmationPopupProps = {
  isShown: boolean;
  onClose: () => void;
};
function MessageConfirmationPopup({ isShown, onClose }: MessageConfirmationPopupProps) {
  const { icon, heading, subheading, phoneNumber } = likeToLearnMore.messageConfirmation;
  return (
    <Modal isShown={isShown} onClose={onClose}>
      <Form className="max-w-form items-center rounded-3xl md:w-form">
        <CheckCircleGreenIcon />
        <Text intent="heading-5" className="mx-4">
          {heading}
        </Text>
        <Text intent="body" className="txt-lg px-4 text-center text-zembl-forest">
          {subheading}
        </Text>
        <Text intent="body" className="text-center font-semibold text-zembl-forest">
          {phoneNumber}
        </Text>
      </Form>
    </Modal>
  );
}

export function ContactUsForm({ className }: any) {
  const { heading, subheading, fields, button } = likeToLearnMore.contactUsForm;
  const { interests, message, ...inputs } = fields;

  const { isMessageSubmitted, toggleMessageSubmitted, control, onSubmit, error } = useContactUs();

  return (
    <div className={className}>
      <Form
        className={cn(
          'max-w-form items-center pb-12 pt-8 sm:pb-24  sm:pt-16 md:w-form md:pb-20 md:pt-12',
          className
        )}
        onSubmit={onSubmit}
        error={error}
      >
        <Text intent="heading" className="mb-2 sm:mb-4 md:mb-5">
          {heading}
        </Text>
        <Text intent="body" className="mb-5 w-48 text-center sm:mb-8 sm:w-64 md:mb-10 md:w-111">
          {subheading}
        </Text>

        <div className="grid grid-cols-1 items-center justify-center gap-3 text-center sm:gap-5 md:grid-cols-2">
          {Object.values(inputs).map((input) => (
            <ControlledInput key={input.name} control={control} placeholder={input.label} {...input} />
          ))}
          <ControlledTextarea
            className="rounded-full"
            control={control}
            placeholder={message.label}
            {...message}
          />
          <div className="col-span-1 col-start-1 col-end-3 md:col-span-2">
            <Button className="w-full">{button}</Button>
          </div>
        </div>
      </Form>
      <MessageConfirmationPopup isShown={isMessageSubmitted} onClose={toggleMessageSubmitted} />
    </div>
  );
}

const InterestCheckboxes = () => {
  const interests = ['Electricity', 'Gas', 'Telephone & Mobile', 'Internet', 'Solar', 'Business Loans'];
  const heading = `I'm interested in:`;
  return (
    <div className="container mx-auto pt-4">
      <strong className="mb-4 text-zembl-forest  ">{heading}</strong>
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
        {interests.map((interest, index) => (
          <div key={index} className="flex items-center">
            <input type="checkbox" id={`interest-${index}`} className="mr-2" />
            <label htmlFor={`interest-${index}`} className="font-sequel text-lg  text-zembl-forest">
              {interest}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

export function ContactUsPage() {
  const { heading, subheading, fields, button } = likeToLearnMore.contactUsForm;
  const { interests, message, ...inputs } = fields;

  const { isMessageSubmitted, toggleMessageSubmitted, control, onSubmit, error } = useContactUs();

  return (
    <div className="w-full  items-center rounded-full bg-zembl-forest  ">
      <Form className={cn('items-center pb-36 pt-24')} onSubmit={onSubmit} error={error}>
        <Text intent="custom" className="text-center  text-4xl    text-zembl-forest">
          {heading}
        </Text>
        <Text
          intent="custom"
          className="mb-10 mt-4 px-20 text-center  text-3xl text-zembl-forest drop-shadow"
        >
          {subheading}
        </Text>
        <div className="-mx-2 flex flex-wrap   px-24">
          {Object.values(inputs).map((input, idx) => (
            <div key={input.name} className="mt-4  w-1/2 px-5 ">
              <ControlledInput control={control} placeholder={input.label} {...input} readOnly />
            </div>
          ))}
        </div>
        <div className="flex w-full flex-col ">
          <div className="mb-6 flex flex-col px-28">
            <InterestCheckboxes />
          </div>
          <div className="mb-6 flex flex-col  px-28">
            <ControlledTextarea control={control} placeholder={message.label} {...message} />
          </div>
          <div className="mb-6 flex flex-col  px-12">
            <Button intent="green" className="w-full text-lg font-med-bold shadow-2xl">
              {button}
            </Button>
          </div>
        </div>
      </Form>
      <MessageConfirmationPopup isShown={isMessageSubmitted} onClose={toggleMessageSubmitted} />
    </div>
  );
}
