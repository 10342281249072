import classNames from 'classnames';
import { useToggle } from 'domain/hooks';
import { SunIcon, clear } from 'domain/assets';

type BannerProps = {
  children: React.ReactNode;
};
export function Banner({ children }: BannerProps) {
  const [isShown, toggleIsShown] = useToggle(true);

  return isShown ? (
    <div
      className={classNames(
        '   flex items-center justify-center gap-2 rounded bg-zembl-violet px-12 py-2 font-body text-tooltip font-semibold'
      )}
    >
      <SunIcon width={30} />
      {children}
      <button onClick={toggleIsShown}>
        <img src={clear} alt="Clear" />
      </button>
    </div>
  ) : (
    <></>
  );
}
