import { phone, email, checkCircle, twitter, facebook, linkedin, instagram } from 'domain/assets';
const contactUsFormFields = {
  firstName: {
    name: 'firstName',
    required: true,
    label: 'John',
    error: 'Error - form field contains an error',
  },
  surname: {
    name: 'surname',
    required: true,
    label: 'Smith',
    error: 'Error - form field contains an error',
  },
  email: {
    name: 'email',
    required: true,
    label: 'john@email.com',
    error: 'Error - form field contains an error',
  },
  phone: {
    name: 'phone',
    label: '0412 345 678',
    error: 'Error - form field contains an error',
  },
  interests: {
    className: 'col-span-2',
    name: 'interests',
    label: "I'm interests in:",
    options: [
      { name: 'Electricity', value: 'electricity' },
      { name: 'Gas', value: 'gas' },
      { name: 'Telephone & mobile', value: 'telephone' },
      { name: 'Internet', value: 'internet' },
      { name: 'Solar', value: 'solar' },
      { name: 'Business Loans', value: 'loans' },
    ],
    error: 'Error - form field contains an error',
  },
  message: {
    className: 'col-span-2',
    name: 'message',
    label: 'Message',
    error: 'Error - form field contains an error',
  },
};

export const likeToLearnMore = {
  contactUsForm: {
    heading: 'Like to learn more?',
    subheading:
      'Simply complete the details below and we’ll get back to you – or contact us using the channels on the right.',
    fields: contactUsFormFields,
    button: 'Enquire Now',
  },
  messageConfirmation: {
    icon: { src: checkCircle, alt: 'Check circle' },
    heading: 'Thank You',
    subheading:
      'We’ve received your enquiry and will have one of our specialists contact you shortly – if this number comes up on your phone it’s us! ',
    phoneNumber: '  (02) 9212 1234 ',
  },
  externalLinks: {
    phone: {
      icon: {
        src: phone,
        alt: 'phone',
      },
      url: 'tel:1300957721',
      description: '1300 957 721',
    },
    email: {
      icon: {
        src: email,
        alt: 'email',
      },
      url: 'mailto:hello@zembl.com.au',
      description: 'hello@zembl.com.au',
    },
    socialMedias: [
      {
        icon: {
          src: twitter,
          alt: 'twitter',
        },
        url: 'https://twitter.com/zemblau',
      },
      {
        icon: {
          src: facebook,
          alt: 'facebook',
        },
        url: 'https://facebook.com/zemblaus',
      },
      {
        icon: {
          src: linkedin,
          alt: 'linkedin',
        },
        url: 'https://linkedin.com/company/zemblau',
      },
      {
        icon: {
          src: instagram,
          alt: 'instagram',
        },
        url: 'https://www.instagram.com/zemblaus',
      },
    ],
  },
};
