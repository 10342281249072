import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useValidateInvitation, useValidateEmailTokenQuery } from 'domain/queries';
import { useAuthStore } from 'domain/state';
import { useVerificationStore } from 'domain/state';

export const useTokenFromUrl = () => {
  const { search } = useLocation();
  const token = new URLSearchParams(search).get('token');
  const verifyCustomerToken = new URLSearchParams(search).get('verify_token');
  const setAuthTokenInStore = useAuthStore((state) => state.setUrlToken);
  const resetAuthStoreCache = useAuthStore((state) => state.resetCache);
  const [verifiedUserData, setVerifiedUserData] = useState(null);

  const [verificationSuccessful, setVerificationSuccessful] = useState(false);
  const { verificationFailed, setVerificationFailed } = useVerificationStore();

  const { mutate: validateInvitation } = useValidateInvitation({
    onSuccess: () => setVerificationSuccessful(true),
    onError: () => setVerificationFailed(true),
  });

  const { mutate: validateEmailToken } = useValidateEmailTokenQuery({
    onSuccess: (data) => {
      setVerificationSuccessful(true);
      setVerifiedUserData(data.data);
    },
    onError: () => setVerificationFailed(true),
  });

  useEffect(() => {
    if (token) {
      validateInvitation({ token });
      setAuthTokenInStore(token);
    } else if (verifyCustomerToken) {
      validateEmailToken({ token: verifyCustomerToken });
      setAuthTokenInStore(verifyCustomerToken);
    } else {
      resetAuthStoreCache();
    }
  }, [token, verifyCustomerToken]);

  return { token, verificationSuccessful, verificationFailed, verifiedUserData, setVerificationFailed };
};
