import classNames from 'classnames';
import { Control, useController } from 'react-hook-form';
import { Input, InputProps } from './input.component';

export type ControlledInputProps = {
  control: Control<any, any>;
  name: string;
  inputClassName?: any;
} & InputProps;

export function ControlledInput({
  name,
  control,
  className,
  inputClassName,
  ...props
}: ControlledInputProps) {
  const {
    field: { ref, ...fields },
    fieldState: { invalid, error },
  } = useController({ name, control });

  return (
    <div className={classNames('flex flex-col', className)}>
      <Input {...fields} {...props} className={inputClassName} />
      {invalid && <span className="text-red-500">{error.message}</span>}
    </div>
  );
}
