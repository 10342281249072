import { useState } from 'react';
import { Banner, Form, Modal, PageLayout, Select, Text, Button } from 'domain/common/components';
import { dashboard } from 'domain/common/constants';
import { useToggle } from 'domain/hooks';
import { ZemAds } from 'domain/assets';
import { useNavigate } from 'react-router-dom';
import { DASHBOARD_ROUTE } from 'domain/common/constants';
import { CreateAccountOverlay } from './components/create-account-overlay.component';
import { HeaderCarousel } from './header/header-carousel.component';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Pie,
  PieChart,
  Tooltip,
  Cell,
  ResponsiveContainer,
  Legend,
  LineChart,
  Line,
} from 'recharts';
const pieData = [
  { name: 'Electricity & Gas', value: 500 },
  { name: 'Telephone & Mobile', value: 250 },
  { name: 'Internet', value: 100 },
  { name: 'Water', value: 150 },
];
const colors = ['#19292C', '#D8BCFF', '#90A9B4', '#2C5D60'];
const legendItems = [
  { value: 'Electricity & Gas', color: '#19292C' },
  { value: 'Telephone & Mobile', color: '#D8BCFF' },
  { value: 'Internet', color: '#90A9B4' },
  { value: 'Water', color: '#2C5D60' },
];

const barData = [
  {
    name: 'Nov',
    uv: 2000,
    pv: 2400,
    amt: 2400,
    tnt: 2000,
  },
  {
    name: 'DEC',
    uv: 2000,
    pv: 2400,
    amt: 2400,
    tnt: 2000,
  },
  {
    name: 'Jan',
    uv: 3000,
    pv: 1398,
    amt: 2210,
    tnt: 1000,
  },
  {
    name: 'Feb',
    uv: 2000,
    pv: 5800,
    amt: 2290,
    tnt: 1000,
  },
  {
    name: 'Mar',
    uv: 2780,
    pv: 3908,
    amt: 2000,
    tnt: 3000,
  },
  {
    name: 'Apr',
    uv: 1890,
    pv: 4800,
    amt: 2181,
    tnt: 1500,
  },
  {
    name: 'May',
    uv: 2390,
    pv: 1200,
    amt: 2500,
    tnt: 1400,
  },
  {
    name: 'Jun',
    uv: 2780,
    pv: 3908,
    amt: 2000,
    tnt: 3000,
  },
  {
    name: 'Jul',
    uv: 2000,
    pv: 5800,
    amt: 2290,
    tnt: 1000,
  },
  {
    name: 'Aug',
    uv: 2000,
    pv: 5800,
    amt: 2290,
    tnt: 1000,
  },
  {
    name: 'Sep',
    uv: 2780,
    pv: 3908,
    amt: 2000,
    tnt: 3000,
  },
  {
    name: 'Oct',
    uv: 2390,
    pv: 1200,
    amt: 2500,
    tnt: 1400,
  },
];
const data = [
  { name: 1, cost: 4.11, impression: 100 },
  { name: 2, cost: 2.39, impression: 120 },
  { name: 3, cost: 1.37, impression: 150 },
  { name: 4, cost: 1.16, impression: 180 },
  { name: 5, cost: 2.29, impression: 200 },
  { name: 6, cost: 3, impression: 499 },
  { name: 7, cost: 0.53, impression: 50 },
  { name: 8, cost: 2.52, impression: 100 },
  { name: 9, cost: 1.79, impression: 200 },
  { name: 10, cost: 2.94, impression: 222 },
  { name: 11, cost: 4.3, impression: 210 },
  { name: 12, cost: 4.41, impression: 300 },
  { name: 13, cost: 2.1, impression: 50 },
  { name: 14, cost: 8, impression: 190 },
  { name: 15, cost: 0, impression: 300 },
  { name: 16, cost: 9, impression: 400 },
  { name: 17, cost: 3, impression: 200 },
  { name: 18, cost: 2, impression: 50 },
  { name: 19, cost: 3, impression: 100 },
  { name: 20, cost: 7, impression: 100 },
];
const initialState = {
  data: data,
  left: 'dataMin',
  right: 'dataMax',
  refAreaLeft: '',
  refAreaRight: '',
  top: 'dataMax+1',
  bottom: 'dataMin-1',
  top2: 'dataMax+20',
  bottom2: 'dataMin-20',
  animation: true,
};

const SolarPromotionBanner = () => {
  const { description, link } = dashboard.banner;
  return (
    <Banner>
      <Text intent="tooltip">{description}</Text>
      <a href={link.href}>
        <Text intent="tooltip-link">{link.label}</Text>
      </a>
    </Banner>
  );
};
const AdsCard = () => {
  return (
    <div className="relative mr-4 flex w-[24rem] flex-col  self-stretch rounded-[1.875rem] bg-[#2C5D60]  shadow-lg">
      <h1 className="absolute left-9 top-8 text-h3 text-white">{'Want the latest energy market'}</h1>
      <div className="absolute left-9 top-32 text-h3 text-white">{' updates?'}</div>
      <a
        href="https://www.zembl.com.au/blog"
        target="_blank"
        className="shadow-custom absolute left-6 top-48  h-[3.25rem] items-center rounded-[0.3125rem]  bg-zembl-green px-[2.25rem] py-[0.8rem] font-sequel text-1.25 font-extrabold leading-[1.625rem] tracking-[0.05rem] text-zembl-forest"
        rel="noreferrer"
      >
        {'READ MORE'}
      </a>
      <div className="flex h-full w-full items-end justify-end rounded-lg bg-zembl-teal">
        <img src={ZemAds} className="  h-4/5 rounded-lg" />
      </div>
    </div>
  );
};
const ZembleServiceSection = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="flex h-140 w-full flex-col justify-between overflow-hidden bg-zembl-forest xs:items-start  lg:items-center ">
        <div className="my-zembl mt-12">My Zembl</div>
        <div className=" text-center text-white">
          {'​Here are the Zembl services that could save you time and improve your bottom line.'}
        </div>
        <div className="container">
          <HeaderCarousel />
        </div>
        <div>
          <Button
            intent="green"
            onClick={() => {
              navigate(DASHBOARD_ROUTE.CONTACT);
            }}
            className=" mb-12 w-120 font-body text-lg"
          >
            {'Don’t have a bill handy or need help? Contact our team'}
          </Button>
        </div>
      </div>
    </>
  );
};

const YourSavingsPotentialSection = () => {
  const { yourSavingsPotentl, yourSpendForcast } = dashboard;
  const { dateRange, category } = yourSavingsPotentl.filters;
  const { upcomingBils, futureSpend } = yourSpendForcast.placeholders;
  const { data, left, right, refAreaLeft, refAreaRight, top, bottom, top2, bottom2 } = initialState;
  const [isCreatingAccount, toggleIsCreatingAccount] = useToggle(false);

  return (
    <>
      <section className=" flex flex-col items-center p-5">
        <div className="mb-10 flex flex-col items-center justify-center">
          <div className="mb-5   text-center font-sequel text-32 font-med-thin leading-[2.75rem] tracking-[0.02rem] text-zembl-ocean">
            {yourSavingsPotentl.heading}
          </div>
          <div className="  mb-6 text-center font-sequel text-1.25 font-med-thin leading-[1.6875rem] tracking-[0.0125rem] text-zembl-forest">
            {yourSavingsPotentl.subheading}
          </div>

          <div className="flex gap-5">
            <Select
              disabled
              className="w-[360px]"
              labelClassName="text-zembl-ocean font-sequel text-1.25 font-extrabold leading-[1.625rem] tracking-[0.05rem]"
              {...dateRange}
            />
            {/* @TODO: remove arbitary value */}
            <Select
              label="Select Category"
              disabled
              className="w-[265px] rounded-3xl"
              labelClassName="text-zembl-ocean font-sequel text-1.25 font-extrabold leading-[1.625rem] tracking-[0.05rem]"
              {...category}
            />
          </div>
        </div>

        <div className="relative mb-8 flex gap-4">
          <div className="absolute inset-0 z-10 flex items-center justify-center">
            <CreateAccountOverlay
              onCreateAccount={toggleIsCreatingAccount}
              OverlayText="Unlock spending insights"
            />
          </div>
          <div className="rounded-lg bg-white pb-4 opacity-60 drop-shadow-input">
            <div className="font-Sequel pt-4 text-center text-h4 text-zembl-ocean">History</div>
            <ResponsiveContainer width={700} height={400}>
              <BarChart
                data={barData}
                margin={{
                  top: 20,
                }}
              >
                <CartesianGrid strokeWidth={0.25} stroke="#90A9B4" />
                <XAxis dataKey="name" stroke="gray" />
                <YAxis domain={[0, 20000]} />
                <Tooltip />
                <Bar dataKey="uv" stackId="a" fill="#19292C" />

                <Bar dataKey="amt" stackId="a" fill="#D8BCFF" />
                <Bar dataKey="pv" stackId="a" fill="#90A9B4" />
                <Bar dataKey="pv" stackId="a" fill="#2C5D60" />
              </BarChart>
            </ResponsiveContainer>
          </div>
          <div className="rounded-lg bg-white pb-4 opacity-60 drop-shadow-input ">
            <div className="font-Sequel pt-4 text-center text-h4 text-zembl-ocean">
              Spend by category
            </div>

            <PieChart width={550} height={400}>
              <Pie data={pieData} dataKey="value" labelLine={false}>
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                ))}
              </Pie>
              <Legend layout="vertical" align="right" verticalAlign="middle" payload={legendItems} />{' '}
            </PieChart>
          </div>
        </div>

        <div className="my-3  mb-8  flex">
          <div className="flex drop-shadow-input">
            <AdsCard />
            <div className="absolute inset-0 left-40 z-50 flex items-center justify-center">
              <CreateAccountOverlay
                onCreateAccount={toggleIsCreatingAccount}
                OverlayText="Unlock spend comparison"
              />
            </div>
            <div className="  flex flex-col items-center rounded-lg   bg-white opacity-80 ">
              <div className="font-sequelSans    text-center   text-[2.08rem] text-h4 font-normal leading-[2.75rem]   tracking-[0.02rem] text-[#90A9B4] opacity-80">
                How does your business compare?
              </div>
              <div className=" w-[42rem] text-center text-[1.75rem] font-normal leading-[2rem] tracking-[0.0525rem] text-[#90A9B4]  opacity-90">
                See if you’re spending more or less than businesses like yours.
              </div>
              <div className="my-[1rem]">
                <Select
                  disabled
                  className=" h-[2rem] w-[13.5rem] rounded-xl text-sm"
                  labelClassName="text-zembl-ocean font-sequel  font-extrabold leading-[1.625rem] tracking-[0.05rem]"
                  {...category}
                />
              </div>
              <LineChart width={860} height={400} data={data}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis allowDataOverflow dataKey="name" domain={[left, right]} type="number" />
                <YAxis allowDataOverflow domain={[bottom, top]} type="number" yAxisId="1" />
                <YAxis
                  orientation="right"
                  allowDataOverflow
                  domain={[bottom2, top2]}
                  type="number"
                  yAxisId="2"
                />
                <Line
                  yAxisId="1"
                  type="natural"
                  dataKey="cost"
                  stroke="#19292C"
                  strokeWidth={2}
                  animationDuration={300}
                />
                <Line
                  yAxisId="2"
                  type="natural"
                  dataKey="impression"
                  stroke="#90A9B4"
                  strokeWidth={2}
                  animationDuration={300}
                />
              </LineChart>
            </div>
          </div>
        </div>

        <div className="font-sequelSans  mb-3 text-center text-[2rem] font-normal leading-[2.75rem] tracking-[0.02rem] text-[#90A9B4]">
          {yourSpendForcast.heading}
        </div>

        <div className="relative flex gap-4">
          <div className="absolute inset-0 z-50 flex items-center justify-center">
            <CreateAccountOverlay
              onCreateAccount={toggleIsCreatingAccount}
              OverlayText="Unlock budgeting insights"
            />
          </div>
          <div className="opacity-60 drop-shadow-input">
            <img src={upcomingBils} alt="placeholder-upcomingBils" />
          </div>
          <div className="opacity-60 drop-shadow-input ">
            <img src={futureSpend} alt="placeholder-futureSpend" />
          </div>
        </div>
      </section>
      {isCreatingAccount && (
        <Modal isShown={isCreatingAccount} onClose={toggleIsCreatingAccount}>
          <Form className="max-w-form items-center md:w-form">
            <p>Create Account Form</p>
          </Form>
        </Modal>
      )}
    </>
  );
};

export function Dashboard() {
  return (
    <PageLayout>
      <SolarPromotionBanner />

      <ZembleServiceSection />

      <YourSavingsPotentialSection />
    </PageLayout>
  );
}
