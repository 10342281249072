import { CheckCircleGreenIcon } from 'domain/assets';
import cn from 'classnames';
export const Card = ({ title, message, children, iconPosition, className }: any) => {
  return (
    <div
      className={cn(
        'flex h-110 w-120 flex-col items-center justify-center bg-zembl-white px-10',
        className
      )}
    >
      {iconPosition === 'top' && <CheckCircleGreenIcon />}
      {title && <p className="mb-4 text-3xl">{title}</p>}
      {iconPosition === 'middle' && <CheckCircleGreenIcon />}
      {message && <div className="justify-center text-center text-xl font-med-thin">{message}</div>}
      {iconPosition === 'bottom' && <CheckCircleGreenIcon />}
      {children}
    </div>
  );
};
