import React, { useState } from 'react';
import { Modal, PageLayout, Text, Form, Button, ControlledInput } from 'domain/common/components';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { useUpdatePasswordQuery } from 'domain/queries';
import { useAuthStore } from 'domain/state';
import { checkCircleGreen } from 'domain/assets';
import { useToggle } from 'domain/hooks';
import { CheckCircleGreenIcon } from 'domain/assets';
async function checkCurrentPassword(password: string): Promise<boolean> {
  // Replace this with your actual implementation to verify the password
  const isCorrect = true; // Example value, replace with actual implementation
  return isCorrect;
}

const passwordSchema = z
  .object({
    currentPassword: z.string().regex(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$/, {
      message: 'Your password does not meet the requirements.',
    }),
    newPassword: z.string().regex(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$/, {
      message: 'Your password does not meet the requirements.',
    }),
    confirmNewPassword: z.string().nonempty('Re-enter password is required'),
  })
  .refine((data) => data.newPassword === data.confirmNewPassword, {
    path: ['confirmNewPassword'],
    message: "Passwords don't match",
  });

const messageConfirmation = {
  icon: { src: checkCircleGreen, alt: 'Check circle' },
  resetPasswordHeading: ' Password Reset',
  resetPasswordSubheading:
    'If you forget your password, you can reset at any time. We recommend regularly updating your password.',
};

export const SecuritySettings = () => {
  const header = 'Security';
  const subHeader = 'We recommend changing your password regularly for optimal security.';
  const { icon, resetPasswordHeading, resetPasswordSubheading } = messageConfirmation;
  const [isResettingPassword, setIsResettingPassword] = useToggle(false);
  const authToken = useAuthStore((state) => state?.user?.auth_token);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const { control, handleSubmit } = useForm({
    resolver: zodResolver(passwordSchema),
    mode: 'onBlur',
  });

  const { mutate: updatePassword } = useUpdatePasswordQuery({
    onSuccess: () => {
      console.log('Password updated successfully');
      setIsModalOpen(true);
    },
    onError: (err) => {
      console.log('Failed to update password', err);
    },
  });
  const handleChangePassword = (data: any) => {
    updatePassword({
      token: authToken,
      oldPassword: data?.currentPassword,
      password: data?.newPassword,
    });
  };

  return (
    <PageLayout>
      <div className="flex h-screen flex-col items-center  justify-start">
        <Text
          intent="heading"
          className=" my-6 text-center font-sequel text-[2.75rem] font-med-thin leading-[3.125rem] tracking-[0.0275rem] text-zembl-forest"
        >
          {header}
        </Text>

        <Text
          intent="body"
          className="mb-8 w-100 text-center  font-sequel text-[1.75rem]  text-h4 font-med-bold  leading-[1.625rem] leading-[1.625rem]  text-zembl-ocean"
        >
          {subHeader}
        </Text>
        <Form
          onSubmit={handleSubmit(handleChangePassword)}
          className="w-120  max-w-form items-center gap-4 rounded-3xl border  border-current bg-zembl-white"
        >
          <div className=" grid  grid-cols-1">
            <div className="mb-3">
              <label
                htmlFor="currentPassword"
                className="mb-1 text-body font-med-bold leading-[1.5rem] tracking-[0.0125rem] text-zembl-forest"
              >
                Current Password
              </label>
              <ControlledInput control={control} name="currentPassword" type="password" placeholder="" />
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label
                  htmlFor="newPassword"
                  className=" text-body font-med-bold leading-[1.5rem] tracking-[0.0125rem] text-zembl-forest"
                >
                  New Password
                </label>
                <ControlledInput control={control} name="newPassword" type="password" placeholder="" />
              </div>
              <div>
                <label
                  htmlFor="confirmNewPassword"
                  className="  text-body font-med-bold leading-[1.5rem] tracking-[0.0125rem] text-zembl-forest"
                >
                  Re-enter New Password
                </label>
                <ControlledInput
                  control={control}
                  name="confirmNewPassword"
                  type="password"
                  placeholder=""
                />
              </div>
            </div>
          </div>
          <div className="mt-4 w-full">
            <Button intent="green" className="w-full" type="submit">
              Update Settings
            </Button>
          </div>
        </Form>
      </div>
      <Modal isShown={isResettingPassword} onClose={setIsResettingPassword}>
        <Form className=" w-110  items-center  rounded-2xl">
          <CheckCircleGreenIcon width="40" className="mb-4" />
          <div className="text-center font-sequel text-[1.75rem] font-med-bold leading-[1.625rem] tracking-[0.0525rem] text-zembl-forest">
            {resetPasswordHeading}
          </div>
          <Text
            intent="body"
            className="mt-4 px-3 text-center font-sequel text-[1.25rem] font-med-thin leading-[1.625rem] tracking-[0.05rem] text-zembl-forest"
          >
            {resetPasswordSubheading}
          </Text>
        </Form>
      </Modal>
    </PageLayout>
  );
};
