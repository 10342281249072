export const HOME_ROUTE = {
  HOME: '/',
  HOME_VERIFY_EMAIL: '/verify-email',
  HOW_IT_WORLS: '/#how-it-works',
  CONTACT: '/#contact',
  INVITE: '/invite',
} as const;

export const DASHBOARD_ROUTE = {
  DASHBOARD: '/dashboard',
  OFFERS: '/offers',
  CONTACT: '/contact',
  ACCOUNT_SETTINGS: '/account-settings',
  SECURITY_SETTINGS: '/security-settings',
} as const;

export const DASHBOARD_OFFER_ROUTE = {
  SOLAR_ENERGY: `${DASHBOARD_ROUTE.OFFERS}/solar-energy`,
  BUSINESS_LOAN: `${DASHBOARD_ROUTE.OFFERS}/business-loan`,
} as const;
