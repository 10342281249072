// verification.store.ts
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

type VerificationStore = {
  verificationFailed: boolean;
  setVerificationFailed: (value: boolean) => void;
  resetPasswordFailed: boolean;
  setResetPasswordFailed: (value: boolean) => void;
};

export const useVerificationStore = create<VerificationStore>()(
  devtools((set) => ({
    verificationFailed: false,
    setVerificationFailed: (value) => {
      set({ verificationFailed: value });
    },
    resetPasswordFailed: false,
    setResetPasswordFailed: (value) => {
      set({ resetPasswordFailed: value });
    },
  }))
);
