import { z } from 'zod';
import { login } from '../common/constants/login.constants';

const { email: loginFormEmail, password } = login.loginForm.fields;

export const loginFormSchema = z.object({
  email: z.string().email({ message: loginFormEmail.error }),
  password: z.string().regex(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$/, {
    message: password.error,
  }),
});

const { email: forgotPasswordFormEmail } = login.forgotPasswordForm.fields;

export const forgotPasswordFormSchema = z.object({
  email: z.string().email({ message: forgotPasswordFormEmail.error }),
});
