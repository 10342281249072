import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { login } from 'domain/common/constants/login.constants';
import { Card, Button, ControlledInput, Form } from 'domain/common/components';
import cn from 'classnames';
import { useUpdatePasswordQuery } from 'domain/queries';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { useVerificationStore } from 'domain/state';

export const resetPasswordFormSchema = z.object({
  password: z.string().min(8, 'Password must be at least 8 characters long'),
  confirmPassword: z.string().min(8, 'Password must be at least 8 characters long'),
});

export function useResetPasswordForm({ onSuccess }: any) {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const resetPasswordToken = queryParams.get('resetPassword');
  const { setResetPasswordFailed } = useVerificationStore();

  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
    resolver: zodResolver(resetPasswordFormSchema), // Replace with your validation schema
    mode: 'onBlur',
  });

  const { mutate: updatePassword } = useUpdatePasswordQuery({
    onSuccess: (data) => {
      onSuccess(data);
    },
    onError: (err) => {
      if (err.response.status === 403) {
        setResetPasswordFailed(true);
      }
    },
  });

  const [error] = useState('');
  const handleResetPassword = handleSubmit((data: any) => {
    updatePassword({ token: resetPasswordToken, oldPassword: '', password: data.password }); // Replace oldPassword with actual old password
  });
  return { control, onSubmit: handleResetPassword, error, reset };
}

const PasswordResetVerifiedEmailCard = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = () => {
    const { pathname } = location; // get the current path without query params
    navigate(pathname); // navigate to the current path without query params
  };

  return (
    <Card title="Great!" message="Your password has been reset." iconPosition="top">
      <Button
        onClick={handleClick}
        intent="accent-dark"
        type="submit"
        className="mt-8 w-full  rounded-2xl px-4 py-2 text-3xl   font-semibold  subpixel-antialiased  drop-shadow-lg xs:text-xl   md:text-xl "
      >
        Log in
      </Button>
    </Card>
  );
};

export const ResetPassword = () => {
  const { heading, fields, button } = login.resetPassword;
  const [isPasswordReset, setIsPasswordReset] = useState(false);

  const { control, onSubmit, error } = useResetPasswordForm({
    onSuccess: () => {
      setIsPasswordReset(true);
    },
  });

  const inputClass = cn('rounded border border-black h-10 ');

  return isPasswordReset ? (
    <PasswordResetVerifiedEmailCard />
  ) : (
    <Form
      className="flex h-110 w-120 flex-col items-center justify-center rounded-2xl"
      onSubmit={onSubmit}
      error={error}
    >
      <h2 className="mb-8 text-center text-3xl">{heading}</h2>

      {Object.values(fields).map((field) => (
        <ControlledInput
          className="mb-4  w-full"
          inputClassName={inputClass}
          key={field.name}
          control={control}
          placeholder={field.label}
          {...field}
        />
      ))}
      <Button
        intent="green"
        type="submit"
        className="w-full rounded px-4 py-2 text-xl font-med-bold text-black	 subpixel-antialiased drop-shadow-xl md:text-xl"
      >
        {button}
      </Button>
    </Form>
  );
};
