import {
  LeafIcon,
  MoneyIcon,
  SupportIcon,
  solarEnergy,
  solarContentImg,
  monetizationOn,
  businessLoan,
  contacts,
  phoneInTalk,
  CheckCircleGreenIcon,
  sentimentVerySatisfied,
  DatabaseIcon,
  AvatarIcon,
  WhiteTickIcon,
} from 'domain/assets';
import { DASHBOARD_OFFER_ROUTE } from 'domain/common/constants';
import { OfferCardProps } from '../../pages/offers/components/offer-card.component';

const selectIndustryOptions = [
  { name: 'Business Services', value: 'business' },
  { name: 'Warehousing', value: 'warehousing' },
  { name: 'Health Services', value: 'health' },
  { name: 'Agriculture', value: 'agriculture' },
  { name: 'Hospitality', value: 'hospitality' },
  { name: 'Retail', value: 'retail' },
  { name: 'Trade', value: 'trade' },
  { name: 'Arts and Recreation Services', value: 'arts-and-recreation' },
];
export const solarFields = {
  selectIndustry: {
    name: 'selectIndustry',
    required: true,
    placeholder: 'Select Industry',
    error: 'Error - form field contains an error',
    options: selectIndustryOptions,
  },
};

const cards: OfferCardProps[] = [
  {
    thumbnail: {
      src: solarEnergy,
      alt: 'Solar Energy Thumbnail',
    },
    title: 'Save On Energy Bills With Solar',
    description:
      'A commercial solar system tailored to your business needs helps reduce your daytime electricity costs and your bottom line.',
    cta: {
      label: 'Learn More',
      redirectTo: DASHBOARD_OFFER_ROUTE.SOLAR_ENERGY,
    },
  },
  {
    thumbnail: {
      src: businessLoan,
      alt: 'Business Loan Thumbnail',
    },
    title: 'Get Simple and Easy Business Finance',
    description:
      'Our business lending experts help you find a business loan to meet your needs hassle-free.',
    cta: {
      label: 'Learn More',
      redirectTo: DASHBOARD_OFFER_ROUTE.BUSINESS_LOAN,
    },
  },
];

export const offers = {
  heading: 'Services',
  subheading: 'Exclusive To Zembl Customers',
  cards,
};

export const solar = {
  cta: 'Make an enquiry',
  hero: {
    title: 'Solar',
    description: 'Reduce your daytime electricity costs and your bottom line.',
    tooltips: {
      line1: 'You could save an estimated',
      line2: '$2,000',
      line3: 'per year by switching to Solar energy',
    },
  },
  banner: {
    line1:
      'Feed-in tariffs may be available when you purchase your solar system. Your business may also be eligible for a federal government tax rebate to deduct the cost of your solar system.',
  },
  content: {
    img: {
      src: solarContentImg,
      alt: 'Solar Content Image',
    },
    title: 'Why go solar?',
    description:
      'Going solar can help your business in many ways. First, and most importantly, you could boost your bottom line with savings on electricity and operating costs. Promoting your solar energy credentials may also assist your appeal to customers and suppliers – those looking to support businesses committed to sustainable energy practices. It’s also possible that you’ll be eligible for government rebates. No matter what industry you work in, you can reap benefits from going solar.',
    cards: [
      {
        Icon: MoneyIcon,
        title: 'Get a Competitive Quote for a Custom Install',
        description:
          "Need a competitively-priced quote with the right mix of quality products and services? You've come to the right place. Our quotes consider all aspects of the project from engineering, procurement, construction, safety and ongoing performance.",
      },
      {
        Icon: SupportIcon,
        title: 'Optimise & Support Your System',
        description:
          "Our team can review your solar system to enhance its performance and longevity. If you're wondering how your system is performing, our service team will provide a comprehensive system audit and recommendations.",
      },

      {
        Icon: LeafIcon,
        title: 'Find the Right Solution for Your Business',
        description:
          "If you're unsure how commercial solar will work for your business, our Feasibility Studies are a great place to start. We'll provide you with an accurate model of your energy profile and clarify the cost savings you can expect.",
      },
    ],
  },
};

export const business = {
  cta: 'Make an enquiry',
  hero: {
    title: 'Business Loans',
    description: [
      {
        Icon: CheckCircleGreenIcon,
        content: 'Tailored options from 40+ lenders',
      },
      {
        Icon: CheckCircleGreenIcon,
        content: 'Dedicated Business Lending Expert',
      },
      {
        Icon: CheckCircleGreenIcon,
        content: 'No hidden fees or charges',
      },
    ],
    tooltips: {
      icon: { src: monetizationOn, alt: 'Business Loan Icon' },
      line1: '70% of businesses will be cashflow negative at least once a year. ',
    },
  },
  banner: {
    line1:
      'Zembl offer commercial finance to help our customers upgrade old equipment or to buy new assets    ',
    line2:
      'Our Business Lending Experts will help find the right loan for your business. They will guide you through the process, answer any questions you may have, and provide you with a finance solution that suits your business needs.    ',
  },
  content: {
    title: 'Compare business finance today!',
    description:
      'Join thousands of Aussie businesses who have compared business finance with Zembl to find the right solution for their business needs.',
    optionsHeader: '2 easy ways to get in touch today:',
    option1: `Option 1:  Take a couple of minutes to complete our form.`,
    option1Line2: `You’ll receive a phone call from a Business Lending Expert who will help you with your business finance needs.`,

    option2:
      'Option 2: Simply call us on 1300 957 721 and speak with a Business Lending Expert at Zembl between the hours of 9:00 - 5:00pm, Monday - Friday.',
    cards: [
      {
        Icon: DatabaseIcon,
        title: 'Easy & Simple',
        description:
          'We’ll do all the legwork and get your application submitted and leverage our market position with lenders to advocate for you.',
      },
      {
        Icon: AvatarIcon,
        title: '100% Australian',
        description:
          'Our Sydney based brokers are experts in helping you find a business loan to meet your business needs.',
      },
      {
        Icon: WhiteTickIcon,
        title: 'Competitive Rates',
        description:
          'We compare over 40 of Australia’s leading lenders to help find the right loan for your business.',
      },
    ],
  },
};
