import { useMutation, MutationOptions } from 'react-query';
import axios, { AxiosResponse, AxiosError } from 'axios';
import { useAuthStore } from 'domain/state';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    'X-API-KEY': process.env.REACT_APP_API_KEY,
  },
});
export const createPassword = async ({ uuid, password, token }: any): Promise<any> => {
  const data = {
    uuid,
    password,
    token,
  };
  const response = await api.post('/password', data);
  return response.data;
};

export const updatePassword = async ({ token, oldPassword, password }: any): Promise<any> => {
  return await api.put('/password', {
    token,
    old_password: oldPassword,
    password,
  });
};

export const resetPassword = async ({ email }: any): Promise<any> => {
  return await api.post('/reset_password', { email });
};

export const useCreatePasswordQuery = (options: any) => {
  return useMutation(createPassword, {
    onSuccess: (data) => {
      console.log('data', data);
    },
    ...options,
  });
};

export function useUpdatePasswordQuery(options?: MutationOptions<AxiosResponse<any>, AxiosError, any>) {
  return useMutation(updatePassword, options);
}

export function useResetPasswordMutation(
  options?: MutationOptions<AxiosResponse<any>, AxiosError, any>
) {
  return useMutation(resetPassword, options);
}
