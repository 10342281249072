import classNames from 'classnames';
import React from 'react';

type ModalProps = {
  children: React.ReactNode;
  isShown: boolean;
  onClose: () => void;
  className?: any;
};
export function Modal({ children, isShown, onClose, className }: ModalProps) {
  return (
    <section
      className={classNames(
        className,
        'fixed bottom-0 left-0 right-0 top-0 z-50 flex items-center justify-center rounded-xl bg-zembl-forest/70',
        {
          hidden: !isShown,
        }
      )}
      onClick={onClose}
    >
      <div onClick={(e) => e.stopPropagation()}>
        <div className="mx-auto w-full md:w-full">{children}</div>
      </div>
    </section>
  );
}
