import { PageLayout } from 'domain/common/components';
import { offers } from '../../common/constants/offers.constants';
import { OfferCard } from './components';

export function Offers() {
  const { heading, cards } = offers;

  return (
    <PageLayout>
      <div className="  border-white bg-zembl-forest text-white xs:px-5 xs:pb-5 xs:pt-5 lg:px-24 lg:pb-32 lg:pt-20">
        <h1 className=" mb-10 text-center text-zembl-white xs:text-44  lg:text-110">{heading}</h1>
        <div className="flex flex-col items-center justify-center gap-8 md:flex-row">
          {cards.map((card: any) => (
            <OfferCard key={card.title} {...card} />
          ))}
        </div>
      </div>
    </PageLayout>
  );
}
