import { Card } from 'domain/common/components';
import { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuthStore } from 'domain/state';
import { useVerifyEmailQuery } from 'domain/queries';

export const AccountVerificationCard = () => {
  const { search } = useLocation();
  const contactDetails = useAuthStore((state) => {
    console.log('state', state);
    return state.contactDetails;
  });

  const { mutate: resendEmailVerification } = useVerifyEmailQuery();
  const handleResendClick = () => {
    if (contactDetails?.email) {
      resendEmailVerification({ email: contactDetails?.email });
    }
  };

  return (
    <div className="flex flex-col rounded-xl bg-zembl-white">
      <Card
        title="Just one more quick step"
        message="A verification email has been sent to your email address. Click the verification link in the email to continue."
        className="flex h-96 rounded-xl"
      ></Card>
      <div className="bottom-12 h-24 w-full justify-center text-center ">
        <div className="w-full">{`Didn't receive your verification email?`}</div>
        <div className="w-full  cursor-pointer  pt-4 underline hover:text-blue-500 active:text-blue-500">
          <Link onClick={handleResendClick} to="?resent=true">
            Click here to resend
          </Link>
        </div>
      </div>
    </div>
  );
};
