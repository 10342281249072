import { DASHBOARD_ROUTE } from 'domain/common/constants';
import { history, spendByCategory, businessLikeMine, upcomingBils, futureSpend } from 'domain/assets';
import { Option } from 'domain/common/models';

export const dashboard = {
  banner: {
    description: 'Do we have all your current contact details correct?',
    link: {
      href: DASHBOARD_ROUTE.ACCOUNT_SETTINGS,
      label: 'Update  now.',
    },
  },
  yourSavingsPotentl: {
    heading: 'Spend watch',
    subheading: 'See a personalised view of your business spending across key utilities',
    filters: {
      dateRange: {
        label: 'Date Range',
        placeholder: 'Last 12 Months',
        options: [] as Option[],
      },
      category: {
        label: 'Select Category',
        placeholder: 'All',
        options: [] as Option[],
      },
    },
    placeholders: {
      history,
      spendByCategory,
      businessLikeMine,
    },
  },
  yourSpendForcast: {
    heading: 'Budgeting helper',
    placeholders: {
      upcomingBils,
      futureSpend,
    },
  },
};
