import { industries, signUpForm } from '../common/constants/sign-up.constants';
import { z } from 'zod';

const { firstName, surname, email, phone, abn, password, tnc } = signUpForm.createAccount.fields;

// Create a reusable password schema with validation rules
const passwordSchema = z.string().regex(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$/, {
  message: password.error,
});

/**
 * createAccountFormSchema: A Zod schema for validating account creation form data
 */
export const createAccountFormSchema = z
  .object({
    firstName: z.string().min(1, { message: firstName.error }),
    surname: z.string().min(1, { message: surname.error }),
    email: z.string().email({ message: email.error }),
    phone: z.string().refine((value) => /^(02|03|04|07|08)\d{8}$/.test(value), { message: phone.error }),
    abn: z.string().min(1, { message: abn.error }),
    password: passwordSchema,
    confirmPassword: passwordSchema,
    tnc: z.literal(true, { errorMap: () => ({ message: tnc.error }) }),
  })
  .refine((schema) => schema.password === schema.confirmPassword, {
    message: 'Password does not match',
    path: ['confirmPassword'],
  });

/**
 * onboardingFormSchema: A Zod schema for validating onboarding form data
 */
export const onboardingFormSchema = z.object({
  selectIndustry: z.enum(industries),
  noOfEmployees: z.string().nullable(),
  avgMonthlyTurnover: z.string().nullable(),
});
