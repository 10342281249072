import { solar } from '../../../common/constants/offers.constants';

export const SolarEnergyBanner = () => {
  const { banner } = solar;
  return (
    <section className="ont-body flex items-center justify-center  bg-zembl-violet text-center text-2xl  font-thin text-zembl-forest xs:py-20 lg:py-20">
      <div className="container mx-auto px-64 xs:px-0 sm:px-28 md:px-64 lg:px-64">
        <p className="tracking-0.02 text-32 font-med-thin leading-2.75">{banner.line1}</p>
      </div>
    </section>
  );
};
