import { Text } from 'domain/common/components';
import { ContactUsForm, ContactUsPage } from './contact-us-form.container';
import { likeToLearnMore } from 'domain/common/constants/like-to-learn-more.constants';
import { PageLayout } from 'domain/common/components';

function ExternalLinks() {
  const { phone, email, socialMedias } = likeToLearnMore.externalLinks;
  return (
    <div className="flex flex-col items-center self-center p-12  ">
      <img {...phone.icon} />
      <a href={phone.url}>
        <Text intent="body" className="mb-6 text-zembl-white">
          {phone.description}
        </Text>
      </a>
      <img {...email.icon} />
      <a href={email.url}>
        <Text intent="body" className="mb-6 text-zembl-white">
          {email.description}
        </Text>
      </a>

      <div className="flex gap-6">
        {socialMedias.map((socialMedia) => (
          <a key={socialMedia.url} href={socialMedia.url} target="_blank" rel="noreferrer">
            <img {...socialMedia.icon} />
          </a>
        ))}
      </div>
    </div>
  );
}

export function LikeToLearnMoreSection() {
  return (
    <section
      id="contact"
      className=" flex flex-col  rounded-lg bg-zembl-forest   md:flex-row"
      style={{
        boxShadow: 'rgba(0, 0, 0, 0.4) 0px 4px 31px',
      }}
    >
      <ContactUsForm />
      <ExternalLinks />
    </section>
  );
}

export function ContactUs() {
  return (
    <PageLayout className="border-white bg-zembl-forest text-white">
      <div className="mx-auto w-full max-w-screen-lg items-center py-12 sm:w-5/6">
        <section
          className="flex rounded-2xl border-b border-r border-t bg-zembl-forest  md:flex-row"
          style={{
            boxShadow: 'rgba(0, 0, 0, 0.4) 0px 4px 31px',
          }}
        >
          <ContactUsPage />

          <ExternalLinks />
        </section>
      </div>
    </PageLayout>
  );
}
