import { FormHTMLAttributes } from 'react';
import classNames from 'classnames';

type FormProps = {
  children: React.ReactNode;
  error?: string;
} & FormHTMLAttributes<HTMLFormElement>;
export function Form({ children, error, className, ...props }: FormProps) {
  return (
    <form
      {...props}
      className={classNames(className, 'flex flex-col    bg-zembl-white p-2 xs:p-4 sm:p-6 md:p-8')}
    >
      {children}
      {error && <span className="text-red-500">{error}</span>}
    </form>
  );
}
