import classNames from 'classnames';
import cn from 'classnames';
import { Control, useController } from 'react-hook-form';
import { Select, SelectProps } from './select.component';

export type ControlledSelectProps = {
  name: string;
  control: Control<any, any>;
} & SelectProps;

export function ControlledSelect({ name, control, className, ...props }: ControlledSelectProps) {
  const {
    field: { ref, ...fields },
    fieldState: { error },
  } = useController({ name, control });

  return (
    <div className={classNames('flex flex-col', className)}>
      <Select
        {...fields}
        {...props}
        className={cn(
          'placeholder:black  rounded-3xl border border-black px-5 py-3   drop-shadow-input',
          className
        )}
      />
      {error && <span className="mb-2 text-red-500">{error.message}</span>}
    </div>
  );
}
