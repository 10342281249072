import { Button, Text } from 'domain/common/components';

type CreateAccountOverlayProps = {
  onCreateAccount: () => void;
  OverlayText?: any;
};
export const CreateAccountOverlay = ({ onCreateAccount, OverlayText }: CreateAccountOverlayProps) => {
  return (
    <div className="flex flex-col items-center gap-3 rounded-lg bg-zembl-white p-7 drop-shadow-input">
      <div className="font-sequelSans text-center text-[1.75rem] font-normal leading-[1.625rem] tracking-[0.0525rem] text-[#19292C]">
        {OverlayText ? OverlayText : 'Unlock insights and savings'}
      </div>
      <Button
        className="font-sequelSans text-[1.25rem] font-normal leading-[1.625rem] tracking-[0.05rem] text-[#19292C]"
        intent="green"
        onClick={onCreateAccount}
      >
        Connect account
      </Button>
    </div>
  );
};
