import { useMutation, MutationOptions } from 'react-query';
import axios, { AxiosResponse, AxiosError } from 'axios';
import { useAuthStore } from 'domain/state';

export const createInvitation = async ({ contact_id }: any) => {
  return await axios.request({
    method: 'POST',
    url: `${process.env.REACT_APP_API_BASE_URL}/invitations`,
    headers: {
      'Content-Type': 'application/json',
      'X-API-KEY': process.env.REACT_APP_API_KEY,
    },
    data: {
      contact_id,
    },
  });
};

export const validateInvitation = async ({ token }: any) => {
  return await axios.request({
    method: 'POST',
    url: `${process.env.REACT_APP_API_BASE_URL}/invitations/validate`,
    headers: {
      'Content-Type': 'application/json',
      'X-API-KEY': process.env.REACT_APP_API_KEY,
    },
    data: {
      token,
    },
  });
};

export function useCreateInvitationQuery(
  options?: MutationOptions<AxiosResponse<any>, AxiosError, any>
) {
  const setContactDetails = useAuthStore((state) => state.setContactDetails);

  return useMutation(
    async (data) => {
      return await createInvitation(data);
    },
    {
      onSuccess: (data) => {
        setContactDetails(data.data);
      },
      ...options,
    }
  );
}

export function useValidateInvitation(options?: MutationOptions<AxiosResponse<any>, AxiosError, any>) {
  const setContactDetails = useAuthStore((state) => state.setContactDetails);

  return useMutation(
    async (data) => {
      return await validateInvitation(data);
    },
    {
      onSuccess: (data) => {
        setContactDetails(data.data);
      },
      ...options,
    }
  );
}
