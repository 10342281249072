import { useRef } from 'react';
import cn from 'classnames';
import { FiChevronRight, FiChevronLeft } from 'react-icons/fi';
import Slider from 'react-slick';

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const ReviewAvatar = ({ className }: any) => (
  <svg
    width="114"
    height="115"
    viewBox="0 0 114 115"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M94.7624 3H19.6854C10.3348 3 2.75458 10.5802 2.75458 19.9309V95.0078C2.75458 104.358 10.3348 111.939 19.6854 111.939H94.7624C104.113 111.939 111.693 104.358 111.693 95.0078V19.9309C111.693 10.5802 104.113 3 94.7624 3Z"
      stroke="#19292C"
      strokeWidth="4.44155"
    />
    <path
      d="M57.1672 60.9756C67.6397 60.9756 76.1292 52.486 76.1292 42.0135C76.1292 31.541 67.6397 23.0514 57.1672 23.0514C46.6947 23.0514 38.205 31.541 38.205 42.0135C38.205 52.486 46.6947 60.9756 57.1672 60.9756Z"
      stroke="#19292C"
      strokeWidth="4.44155"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.9147 81.6743C31.9417 69.9407 43.596 61.7136 57.1679 61.7136C70.7398 61.7136 82.394 69.9407 87.4097 81.6743C89.3048 86.1113 86.0139 91.0362 81.1911 91.0362H35.5277"
      stroke="#19292C"
      strokeWidth="4.44155"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const slideData = [
  {
    id: 'slide1',
    name: 'Sue Moshopoulos',
    jobTitle: 'Software Engineer',
    rating: 5,
    content:
      'Worked with Zembl on the establishment of a new large business account for gas and electricity.  They were quick and efficient. Brittany Lovell was so attentive and supportive.  The hassle of finding the right provider for our business was made so much easier with their help.   Would definitely recommend their service.',
  },
  {
    id: 'slide2',
    name: 'Samuel Saputra',
    jobTitle: 'Product Manager',
    rating: 5,
    content:
      "Very quick and simple. Charles Thornton from Zembl was super helpful. Did all the work for me and found the best offer they have. Not forcing me into any offer that's worse than what I have or can get elsewhere.",
  },
  {
    id: 'slide3',
    name: 'Melanie Young',
    jobTitle: 'UX Designer',
    rating: 5,
    content:
      'It took 20 minutes and I had a better price just handed to me! As a business owner its hard to allocate time to look into other prices. Highly recommend Dominic Hall at Zembl he made it easy and hassle free!',
  },
  {
    id: 'slide4',
    name: 'Tracey Merriman',
    jobTitle: 'Marketing Specialist',
    rating: 5,
    content:
      'If you want to ensure you are paying the minimum for your energy bills use Zembl (previously Make It cheaper)! They compare plans for your individual usage and do the paperwork if you decide to change to a cheaper plan. I recommend them to everyone! Kira Shah was especially helpful.',
  },
];

const Star = ({ active }: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill={active ? 'black' : 'none'}
      viewBox="0 0 24 24"
      stroke="currentColor"
      className="h-5 w-5"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 2l3.192 6.446L22 9.266l-5.446 5.42L18.392 22 12 18.266 5.608 22l1.846-7.314L2 9.266l6.808-.82L12 2z"
      />
    </svg>
  );
};
const Rating = ({ rating }: any) => {
  return (
    <div className="my-6 flex items-center">
      {[...Array(5)].map((_, i) => (
        <Star key={i} active={i < rating} />
      ))}
    </div>
  );
};

export const ReviewCard = ({ name, rating, content, className }: any) => {
  return (
    <div className={cn('flex h-full flex-col items-start bg-zembl-citra  py-6', className)}>
      <div className="flex items-center">
        <ReviewAvatar className="mr-4 h-10 w-10  " />
        <div>
          <p className="font-bold">{name}</p>
          {/* <p className="text-gray-500">{jobTitle}</p> */}
        </div>
      </div>
      <Rating rating={rating} />
      <p className="mt-2  overflow-hidden">{content}</p>
    </div>
  );
};

export const HeroSectionSlider = () => {
  const reviewSlider: any = useRef();
  const next = () => {
    reviewSlider?.current.slickNext();
  };
  const previous = () => {
    reviewSlider?.current.slickPrev();
  };
  return (
    <div className="flex h-110 flex-col rounded-lg  bg-zembl-citra xs:mt-6 xs:w-110   xs:px-20 sm:w-115  lg:mt-0 lg:w-72 lg:px-0">
      <div className="h-full flex-grow px-6">
        <Slider {...settings} ref={reviewSlider} arrows={false}>
          {slideData.map((slide, index) => (
            <div id={slide.id} className="h-full " key={`slider-item-${index}`}>
              <ReviewCard
                name={slide.name}
                jobTitle={slide.jobTitle}
                rating={slide.rating}
                content={slide.content}
              />
            </div>
          ))}
        </Slider>
      </div>
      <div className="flex  justify-between pb-0 ">
        <button className="w-24 cursor-pointer self-start" onClick={previous}>
          <FiChevronLeft color="black" size="50px" />
        </button>
        <button className="cursor-pointer self-end" onClick={next}>
          <FiChevronRight color="black" size="50px" />
        </button>
      </div>
    </div>
  );
};
