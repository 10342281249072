import { Button } from 'domain/common/components';
import { solar } from 'domain/common/constants/offers.constants';
import { SunIcon } from 'domain/assets';
import { Text } from 'domain/common/components';
type SolarEnergyHeroSectionProps = {
  onEnquire: () => void;
};

const TriviaCircle = ({ tooltips }: any) => (
  <div className="absolute right-24 mt-10 flex h-90  w-90 flex-col items-center justify-center rounded-full bg-zembl-green text-center text-zembl-forest xs:invisible  xs:px-12 lg:visible lg:px-12">
    <SunIcon width={40} />
    <h5 className="px-4 text-3xl ">{tooltips.line1}</h5>
    <h2 className=" text-4xl font-extrabold">{tooltips.line2}</h2>
    <h5 className="px-6 text-xl font-bold">{tooltips.line3}</h5>
  </div>
);
const ContentSection = ({ title, description, cta, onEnquire }: any) => (
  <div className=" mt-2 flex h-110 flex-col items-start gap-6 p-12 lg:max-w-offer-solar-energy-content">
    <Text
      intent="custom"
      className="tracking-0.06875  text-8xl  text-[6.875rem] font-med-thin leading-6.25 text-zembl-white"
    >
      {title}
    </Text>
    <h3 className="tracking-0.02 px-4 text-2xl font-normal leading-2.75 text-zembl-white">
      {description}
    </h3>
    <Button
      intent="green"
      className="w-90 text-1.25  text-body font-med-bold leading-1.625 text-zembl-forest"
      onClick={onEnquire}
    >
      {cta}
    </Button>
  </div>
);

export const SolarEnergyHeroSection = ({ onEnquire }: SolarEnergyHeroSectionProps) => {
  const {
    hero: { title, description, tooltips },
    cta,
  } = solar;

  return (
    <div className="h-full w-full bg-solar-energy">
      <section className="content-above-shadow">
        <div className="flex  pt-50 lg:container  xs:mx-0 xs:w-full   xs:flex-col sm:flex-row md:flex-col  lg:mx-52 lg:flex-col">
          <TriviaCircle tooltips={tooltips} />
          <ContentSection title={title} description={description} cta={cta} onEnquire={onEnquire} />
        </div>
      </section>
    </div>
  );
};
