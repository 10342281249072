const existingCustomerFormFields = {
  email: {
    name: 'email',
    // required: true,
    label: 'Email',
    disabled: true,
    error: 'Please enter a valid email address',
  },
  password: {
    type: 'password',
    name: 'password',
    required: true,
    disabled: false,
    label: 'Choose password',
    error: 'Error - form field contains an error',
  },
  confirmPassword: {
    type: 'password',
    name: 'confirmPassword',
    required: true,
    disabled: false,
    label: 'Re-enter Password',
    error: 'Error - form field contains an error',
  },
};

const createPasswordFormFields = {
  password: {
    type: 'password',
    name: 'password',
    required: true,
    label: 'Choose password',
    error: 'Error - form field contains an error',
  },
  confirmPassword: {
    type: 'password',
    name: 'confirmPassword',
    required: true,
    label: 'Re-enter Password',
    error: 'Error - form field contains an error',
  },
  tnc: {
    className: 'col-span-2',
    name: 'tnc',
    required: true,
    message: 'I have read and agree to the ',
    errorMessage: '** Password needs 8 characters, 1 Special & 1 Numeric',
    link: {
      text: 'Terms and Conditions',
      url: 'https://www.zembl.com.au/terms-and-conditions',
    },
    error: 'Error - form field contains an error',
  },
  marketingSubscription: {
    className: 'col-span-2',
    name: 'marketingSubscription',
    message: 'I consent to receiving marketing communications',
  },
};

export const loginForm = {
  cta: 'Login',
  existingCustomer: {
    heading: 'Set a password and you’re done',
    fields: existingCustomerFormFields,
    button: 'Register',
    termsAndConditions: ' I have read and agree to the Terms and Conditions',
    alreadyRegistered: 'Already registered?',
    login: ' Log in.',
  },
  createPassword: {
    heading: 'Create Password',
    fields: createPasswordFormFields,
    button: 'Create Account',
  },
};
