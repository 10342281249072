import {
  Button,
  Form,
  Modal,
  PageLayout,
  ControlledSelect,
  ControlledInput,
  Text,
} from 'domain/common/components';

import { CheckCircleGreenIcon } from 'domain/assets';
import { business } from '../../../common/constants/offers.constants';
import { useToggle } from 'domain/hooks';
import { OfferContentCards } from '.';
import { useFormSubmitHookProps } from 'domain/common/models';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

export const loanReasons: any = ['General', 'Equipment', 'Vehicle', 'Property'];

const formOptions = {
  loanAmountOptions: [
    { name: '$5k-$25k', value: '1' },
    { name: '$25k-$100k', value: '5' },
    { name: '$100k-$300k', value: '25' },
    { name: '$300k+', value: '35' },
  ],
  tradingPeriodOptions: [
    { name: '0-5 months', value: '1' },
    { name: '6+ months', value: '5' },
  ],
  selectLoanReasonOptions: [
    { name: 'General', value: 'General' },
    { name: 'Equipment', value: 'Equipment' },
    { name: 'Vehicle', value: 'Vehicle' },
    { name: 'Property', value: 'Property' },
  ],
  avgMonthlyTurnoverOptions: [
    { name: '$0-$5k', value: '$0-$5k' },
    { name: '$5k-$25k', value: '$5k-$25k' },
    { name: '$25k+', value: '$25k+' },
  ],
};

const businessEstimateFields = {
  selectLoanReason: {
    name: 'selectLoanReason',
    required: true,
    placeholder: 'What is the purpose of this loan?',
    error: 'Error - form field contains an error',
    options: formOptions.selectLoanReasonOptions,
  },
  tradingPeriod: {
    name: 'tradingPeriod',
    placeholder: 'How long have you been trading?',
    error: 'Error - form field contains an error',
    options: formOptions.tradingPeriodOptions,
  },
  loanAmount: {
    name: 'loanAmount',
    placeholder: 'What is the loan amount?',
    error: 'Error - form field contains an error',
    options: formOptions.loanAmountOptions,
  },
  avgMonthlyTurnover: {
    name: 'avgMonthlyTurnover',
    placeholder: 'What is your avg monthly turnover?',
    error: 'Error - form field contains an error',
    options: formOptions.avgMonthlyTurnoverOptions,
  },
};

const businessEstimate = {
  fields: businessEstimateFields,
  button: 'Enquire',
};

export const businessEstimateFormSchema = z.object({
  selectLoanReason: z.enum(loanReasons),
  loanAmount: z.string().nullable(),
  tradingPeriod: z.string().nullable(),
  avgMonthlyTurnover: z.string().nullable(),
});

type businessEstimateFormValue = {
  name: string;
  phoneNumber: string;
  selectLoanReason: string;
  loanAmount: string;
  tradingPeriod: string;
  avgMonthlyTurnover: string;
};

const useBusinessEstimate = ({ onSuccess, onError }: useFormSubmitHookProps) => {
  const { control, handleSubmit, reset } = useForm<businessEstimateFormValue>({
    defaultValues: {
      name: 'John Smith',
      phoneNumber: '0412 345 678',
      selectLoanReason: '',
      loanAmount: '',
      tradingPeriod: '',
      avgMonthlyTurnover: '',
    },
    resolver: zodResolver(businessEstimateFormSchema),
    mode: 'onBlur',
  });

  // @TODO: React-Query Mutation
  const [error, setError] = useState('');
  const handleEnquire = handleSubmit(() => {
    onError ? (() => setError('Error - form field contains an error'))() : onSuccess();
  });

  return { control, handleEnquire, error, reset };
};

type BusinessLoanContentProps = {
  onEnquire: () => void;
};
function BusinessLoanContent({ onEnquire }: BusinessLoanContentProps) {
  const { content, cta } = business;
  const { title, description, cards, optionsHeader, option1, option1Line2, option2 } = content;

  return (
    <section className="flex flex-col gap-16 py-12 ">
      <div className="container mx-auto flex max-w-7xl flex-col gap-8  px-12  text-zembl-forest">
        <div className="text-center text-44 font-158 leading-3.125 tracking-0.0275 text-zembl-forest">
          {title}
        </div>
        <p className="text-20 font-158 leading-1.625 tracking-0.05 text-zembl-forest">{description}</p>
        <p className="flex-grow text-20 font-158 leading-1.625 tracking-0.05 text-zembl-forest">
          {optionsHeader}
        </p>
        <p className="flex-grow text-20 font-158 leading-1.625 tracking-0.05 text-zembl-forest">
          {option1}
        </p>
        <p className="flex-grow text-20 font-158 leading-1.625 tracking-0.05 text-zembl-forest">
          {option1Line2}
        </p>
        <p className="flex-grow text-20 font-158 leading-1.625 tracking-0.05 text-zembl-forest">
          {option2}
        </p>
      </div>
      <div className="flex justify-center   text-center text-4xl font-med-thin">Why choose Zembl?</div>
      <div className="container mx-auto">
        <OfferContentCards className="h-70" cards={cards} />
      </div>

      <Button
        className="w-90 self-center text-xl font-med-bold text-zembl-forest shadow-2xl"
        intent="green"
        onClick={onEnquire}
      >
        {cta}
      </Button>
    </section>
  );
}

type BusinessLoanHeroSectionProps = {
  onEnquire: () => void;
};

const BusinessLoanBanner = () => {
  const { banner } = business;
  return (
    <section className="  flex flex-col items-center justify-center gap-2 bg-zembl-violet   py-8 text-center text-body text-zembl-forest">
      <div className="lg:container xs:px-0   lg:mx-auto lg:px-36  ">
        <div className="mb-8 text-center font-sequel text-44 font-158  leading-3.125 tracking-0.0275 text-zembl-forest">
          {banner.line1}
        </div>
        <div className="tracking-0.02 text-center font-sequel text-32 font-158  leading-2.75 text-zembl-forest">
          {banner.line2}
        </div>
      </div>
    </section>
  );
};

const TriviaCircle = ({ tooltips }: any) => (
  <div className="absolute right-24 flex h-96 w-96 flex-col items-center justify-center rounded-full bg-zembl-green xs:invisible lg:visible  ">
    <img {...tooltips.icon} />
    <div className="tracking-0.02 px-12 text-center  font-sequel text-32 font-158 leading-2.75 text-zembl-forest  text-shadow">
      {tooltips.line1}
    </div>
  </div>
);

const HeroContent = ({ title, description, cta, onEnquire }: any) => (
  <div className="flex flex-col items-start gap-4">
    <div className="tracking-0.06875 font-sequel text-6.875 font-158 leading-6.25 text-zembl-white">
      {title}
    </div>
    {description.map(({ Icon, content }: any) => (
      <h4
        key={content}
        className="tracking-0.02 flex gap-8 font-sequel text-32 font-158  leading-2.75 text-zembl-white"
      >
        <Icon className="w-8" />
        {content}
      </h4>
    ))}
    <Button
      intent="green"
      className="  z-50  w-80 items-center justify-center align-middle   text-xl font-med-bold  "
      onClick={onEnquire}
    >
      {cta}
    </Button>
  </div>
);

export const BusinessLoanHeroSection = ({ onEnquire }: BusinessLoanHeroSectionProps) => {
  const {
    hero: { title, description, tooltips },
    cta,
  } = business;

  return (
    <>
      <div className="bg-business-loan ">
        <section className="content-above-shadow">
          <div className="flex lg:container  xs:mx-0 xs:flex-col xs:pt-0   sm:flex-row md:flex-col lg:mx-52  lg:flex-col lg:pt-48">
            <HeroContent title={title} description={description} cta={cta} onEnquire={onEnquire} />
            <TriviaCircle tooltips={tooltips} />
          </div>
        </section>
      </div>
      <BusinessLoanBanner />
    </>
  );
};

const messageConfirmation = {
  Icon: CheckCircleGreenIcon,
  heading: 'Thank You',
  subheading:
    'We’ve received your enquiry and will have one of our specialists contact you shortly. The number we will call from is: ',
  phonNumber: '(02) 9212 1234',
};

export const BusinessLoan = () => {
  const { fields, button } = businessEstimate;
  const [isEnquiringBusiness, toggleIsEnquiringBusiness] = useToggle(false);
  const [showThankYouModal, setShowThankYouModal] = useToggle(false);
  const { Icon, heading, subheading, phonNumber } = messageConfirmation;

  const businessEstimateForm = useBusinessEstimate({
    onSuccess: () => {
      toggleIsEnquiringBusiness();
      setShowThankYouModal();
    },
  });

  return (
    <PageLayout className="items-stretch bg-zembl-background">
      <BusinessLoanHeroSection onEnquire={toggleIsEnquiringBusiness} />
      {/* /notifications/ */}

      <BusinessLoanContent onEnquire={toggleIsEnquiringBusiness} />
      <Modal isShown={isEnquiringBusiness} onClose={toggleIsEnquiringBusiness} className="fixed z-50 ">
        <Form onSubmit={businessEstimateForm.handleEnquire} className="rounded-2xl ">
          <div className="items-center justify-center  px-10 py-8">
            <div className="items-center pb-4  text-center text-28 font-166 leading-1.625 tracking-0.0525 text-zembl-forest">
              Make an enquiry
            </div>
            <div className="flex flex-col items-center justify-center">
              <ControlledInput
                inputClassName=" h-12 "
                className="mb-1.25 mt-1 w-full"
                name="name"
                control={businessEstimateForm.control}
                placeholder="Name"
                readOnly
              />
              <ControlledInput
                inputClassName="h-12 "
                className="mb-1.25 w-full"
                name="phoneNumber"
                control={businessEstimateForm.control}
                placeholder="Phone Number"
                readOnly
              />
            </div>
            {Object.values(fields).map((select, index) => (
              <div className="mb-2 h-10  w-full " key={`${index}-select`}>
                <ControlledSelect
                  className="w-100 "
                  key={select.placeholder}
                  control={businessEstimateForm.control}
                  {...select}
                />
              </div>
            ))}

            <div className="mt-2 w-full  ">
              <Button
                intent="green"
                type="submit"
                className="w-full self-center font-sequel text-20 text-xl   font-166  leading-1.625 tracking-0.05 text-zembl-forest shadow-2xl"
              >
                {button}
              </Button>
            </div>
          </div>
        </Form>
      </Modal>

      <Modal isShown={showThankYouModal} onClose={setShowThankYouModal}>
        <Form className="max-w-form items-center rounded-2xl  md:w-form">
          <Icon width={35} />
          <div className="mb-4 mt-4 text-center font-sequel  text-28 font-166   leading-1.625 tracking-0.0525 text-zembl-teal">
            {heading}
          </div>
          <div className="container  px-20 text-center   font-sequel text-20 text-lg   leading-1.625 tracking-0.05 text-zembl-forest ">
            {subheading}
          </div>
          <Text intent="body" className="font-bold ">
            {phonNumber}
          </Text>
        </Form>
      </Modal>
    </PageLayout>
  );
};
