import {
  Form,
  Modal,
  PageLayout,
  Button,
  ControlledSelect,
  ControlledInput,
  Text,
} from 'domain/common/components';
import { SolarEnergyHeroSection, SolarEnergyBanner, SolarEnergyContent } from './components';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { useFormSubmitHookProps } from 'domain/common/models';
import { useForm, Controller } from 'react-hook-form';
import { useState } from 'react';
import { checkCircleGreen } from 'domain/assets';

export const loanReasons: any = ['General', 'Equipment', 'Vehicle', 'Property'];
type BusinessEstimateFields = {
  [key: string]: any;
};

interface BusinessEstimate {
  fields: BusinessEstimateFields;
  button: string;
}

interface MessageConfirmation {
  icon: React.ImgHTMLAttributes<HTMLImageElement>;
  heading: string;
  subheading: string;
  phonNumber: string;
}
const formOptions = {
  loanAmountOptions: [
    { name: '$5k-$25k', value: '1' },
    { name: '$25k-$100k', value: '5' },
    { name: '$100k-$300k', value: '25' },
    { name: '$300k+', value: '35' },
  ],
  tradingPeriodOptions: [
    { name: '0-5 months', value: '0' },
    { name: '6+ months', value: '5' },
  ],
  selectLoanReasonOptions: [
    { name: 'General', value: 'General' },
    { name: 'Equipment', value: 'Equipment' },
    { name: 'Vehicle', value: 'Vehicle' },
    { name: 'Property', value: 'Property' },
  ],
  avgMonthlyTurnoverOptions: [
    { name: '$0-$5k', value: '$0-$5k' },
    { name: '$5k-$25k', value: '$5k-$25k' },
    { name: '$25k+', value: '$25k+' },
  ],
};

const schema = z.object({
  propertyOwnership: z.any(),
  buildingType: z.any(),
  powerSpending: z.any(),
  name: z.string(),
  phoneNumber: z.string(),
});

type FormData = z.infer<typeof schema>;

const RadioButtonGroup = ({ name, control, question }: any) => (
  <>
    <h3 className="font-med-thin">{question}</h3>
    <div className="flex justify-center py-1.25">
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <>
            <label className="px-1 ">
              <input
                className="mx-1 "
                type="radio"
                value="Yes"
                onChange={() => onChange('Yes')}
                checked={value === 'Yes'}
              />
              Yes
            </label>
            <label className="px-2 ">
              <input
                className="mx-1 "
                type="radio"
                value="No"
                onChange={() => onChange('No')}
                checked={value === 'No'}
              />
              No
            </label>
          </>
        )}
      />
    </div>
  </>
);

export const EnquiryForm = ({ toggleIsEnquiringSolar, setShowThankYouModal }: any) => {
  const { handleSubmit, control } = useForm<FormData>({
    defaultValues: {
      name: 'John Smith',
      phoneNumber: '0412 345 678',
    },
    resolver: zodResolver(schema),
  });

  const onSubmit = () => {
    toggleIsEnquiringSolar();
    setShowThankYouModal(true);
  };

  return (
    <div className="flex flex-col rounded-2xl  bg-zembl-white p-8 text-center align-middle">
      <Text intent="custom" className="text-lg font-semibold">
        Make an enquiry
      </Text>
      <Form
        onSubmit={handleSubmit(onSubmit)}
        className="items-center justify-center rounded-2xl bg-zembl-white text-center"
      >
        <RadioButtonGroup
          name="propertyOwnership"
          control={control}
          question="Do you own the property or have a lease longer than 4 years?"
        />
        <RadioButtonGroup
          name="buildingType"
          control={control}
          question="Are you in a strata, high-rise or multi-tenanted building?"
        />
        <RadioButtonGroup
          name="powerSpending"
          control={control}
          question="Do you spend $450 or more per month on your power?"
        />
        <div className="flex flex-col items-center justify-center">
          <ControlledInput
            inputClassName=" h-10  mb-4 "
            className="w-60 "
            name="name"
            control={control}
            placeholder="Name"
            readOnly
          />
          <ControlledInput
            className=" mb-4 w-60 "
            inputClassName=" h-10 "
            name="phoneNumber"
            control={control}
            placeholder="Phone Number"
            readOnly
          />
        </div>
        <Button intent="green" className="w-60 shadow-xl">
          Enquire
        </Button>
      </Form>
    </div>
  );
};

const businessEstimateFields = {
  selectLoanReason: {
    name: 'selectLoanReason',
    required: false,
    placeholder: 'What is the purpose of this loan?',
    error: 'Error - form field contains an error',
    options: formOptions.selectLoanReasonOptions,
  },
  tradingPeriod: {
    name: 'tradingPeriod',
    placeholder: 'How long have you been trading?',
    error: 'Error - form field contains an error',
    options: formOptions.tradingPeriodOptions,
  },
  loanAmount: {
    name: 'loanAmount',
    placeholder: 'What is the loan amount?',
    error: 'Error - form field contains an error',
    options: formOptions.loanAmountOptions,
  },
  avgMonthlyTurnover: {
    name: 'avgMonthlyTurnover',
    placeholder: 'What is your avg monthly turnover?',
    error: 'Error - form field contains an error',
    options: formOptions.avgMonthlyTurnoverOptions,
  },
};

const businessEstimate: BusinessEstimate = {
  fields: businessEstimateFields,
  button: 'Enquire',
};

export const businessEstimateFormSchema = z.object({
  selectLoanReason: z.enum(loanReasons),
  loanAmount: z.string().nullable(),
  tradingPeriod: z.string().nullable(),
});

type businessEstimateFormValue = {
  selectLoanReason: string;
  loanAmount: string;
  tradingPeriod: string;
};

const useBusinessEstimate = ({ onSuccess, onError }: useFormSubmitHookProps) => {
  const { control, handleSubmit, reset } = useForm<businessEstimateFormValue>({
    defaultValues: {
      selectLoanReason: '',
      loanAmount: '',
      tradingPeriod: '',
    },
    resolver: zodResolver(businessEstimateFormSchema),
    mode: 'onBlur',
  });

  // @TODO: React-Query Mutation
  const [error, setError] = useState('');
  const handleOnboarding = handleSubmit(() => {
    onError ? (() => setError('Error - form field contains an error'))() : onSuccess();
  });

  return { control, handleOnboarding, error, reset };
};
const messageConfirmation: MessageConfirmation = {
  icon: { src: checkCircleGreen, alt: 'Check circle' },
  heading: 'Thank You',
  subheading:
    'We’ve received your enquiry and will have one of our specialists contact you shortly. The number we will call from is:',
  phonNumber: '(02) 9137 5200',
};
export const SolarEnergy = () => {
  const { fields, button } = businessEstimate;
  const { icon, heading, subheading, phonNumber } = messageConfirmation;
  const businessEstimateForm = useBusinessEstimate({ onSuccess: () => console.log('test') });
  const [isEnquiringSolar, setIsEnquiringSolar] = useState(false);
  const [showThankYouModal, setShowThankYouModal] = useState(false);

  const toggleIsEnquiringSolar = () => {
    setIsEnquiringSolar(!isEnquiringSolar);
  };
  const handleSubmit = () => {
    toggleIsEnquiringSolar();
    setShowThankYouModal(true);
  };

  const ControlledSelectFields = () =>
    Object.values(fields).map((select) => (
      <ControlledSelect
        className="mb-2 mt-1 w-full pl-8 pr-8"
        key={select.placeholder}
        control={businessEstimateForm.control}
        {...select}
      />
    ));

  const ThankYouModal = () => (
    <Form className="   w-101 max-w-form items-center rounded-3xl  md:w-form ">
      <img {...icon} />
      <Text intent="heading-5" className="mb-4  text-body text-zembl-teal">
        {heading}
      </Text>
      <Text intent="body" className="mb-2 px-28 text-center">
        {subheading}
      </Text>
      <Text intent="body" className="font-bold ">
        {phonNumber}
      </Text>
    </Form>
  );

  return (
    <PageLayout className="items-stretch bg-zembl-background ">
      <SolarEnergyHeroSection onEnquire={toggleIsEnquiringSolar} />
      <SolarEnergyBanner />
      <SolarEnergyContent onEnquire={toggleIsEnquiringSolar} />
      <Modal isShown={isEnquiringSolar} onClose={toggleIsEnquiringSolar} className="fixed z-50">
        <EnquiryForm
          toggleIsEnquiringSolar={toggleIsEnquiringSolar}
          setShowThankYouModal={setShowThankYouModal}
        />
      </Modal>
      <Modal isShown={showThankYouModal} onClose={() => setShowThankYouModal(false)}>
        <ThankYouModal />
      </Modal>
    </PageLayout>
  );
};
