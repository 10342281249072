import { Button } from 'domain/common/components';
import { useNavigate } from 'react-router-dom';

export type OfferCardProps = {
  thumbnail: any;
  title: string;
  description: string;
  cta: { label: string; redirectTo: string };
};
export function OfferCard({ thumbnail, title, description, cta }: OfferCardProps) {
  const navigate = useNavigate();

  return (
    <div className="relative flex h-115 w-115 flex-col items-center justify-between overflow-hidden rounded-xl bg-zembl-white pb-16">
      <div>
        <img className="top-0 h-44 w-full bg-cover bg-no-repeat align-top" {...thumbnail} />
        <div className="py-6 lg:container xs:w-full sm:w-full sm:px-0 lg:mx-auto lg:px-9">
          <div className="mb-7 text-center text-2xl font-med-thin leading-2.75 tracking-tight text-zembl-forest sm:px-0 lg:px-24">
            {title}
          </div>
          <p className="text-1.25xl flex-grow px-16 text-center text-body font-med-thin leading-1.625 tracking-[0.05rem] text-zembl-forest">
            {description}
          </p>
        </div>
      </div>
      <div className="absolute bottom-2 flex w-full justify-center">
        <Button
          intent="green"
          className="text-1.25xl w-80 text-body font-med-bold text-zembl-forest shadow-2xl"
          onClick={() => navigate(cta.redirectTo)}
        >
          {cta.label}
        </Button>
      </div>
    </div>
  );
}
