import checkCircle from 'domain/assets/check-circle.svg';

export const login = {
  cta: 'Login',
  loginForm: {
    heading: 'Log in to My Zembl',
    fields: {
      email: {
        name: 'email',
        required: true,
        label: 'Email',
        error: 'Error - form field contains an error',
      },
      password: {
        type: 'password',
        name: 'password',
        required: true,
        label: 'Password',
        error: 'Error - form field contains an error',
      },
    },
    forgotPassword: 'Forgot Password',
    button: 'Login',
  },
  forgotPasswordForm: {
    heading: 'Forgot Password?',
    fields: {
      email: {
        name: 'email',
        required: true,
        label: 'Enter your email address',
        error: 'Error - form field contains an error',
      },
    },
    button: 'Reset password',
  },
  passwordResetConfirmation: {
    icon: {
      src: checkCircle,
      alt: 'Check Circle',
    },
    heading: 'Reset Your Password',
    subheading: 'We’ve sent you a reset password link to your email address.',
  },
  resetPassword: {
    heading: 'Forgot your password?',
    fields: {
      password: {
        type: 'password',
        name: 'password',
        required: true,
        label: 'New Password',
        error: 'Error - form field contains an error',
      },
      confirmPassword: {
        type: 'password',
        name: 'confirmPassword',
        required: true,
        label: 'Re-enter new password',
        error: 'Error - form field contains an error',
      },
    },
    button: 'Reset password',
  },
};
