import classNames from 'classnames';
import React, { HTMLAttributes } from 'react';
import ReactMarkdown from 'react-markdown';

type TextIntent =
  | 'heading'
  | 'heading-1'
  | 'heading-2'
  | 'heading-3'
  | 'heading-4'
  | 'heading-5'
  | 'body'
  | 'body-bold'
  | 'subtitle'
  | 'footer'
  | 'input-label'
  | 'error'
  | 'tooltip'
  | 'tooltip-link'
  | 'custom';

type TextProps = {
  children?: any;
  intent: TextIntent;
} & HTMLAttributes<HTMLParagraphElement>;

export function Text({ children, intent, className }: TextProps) {
  const baseStyles: { [key in TextIntent]: string } = {
    heading: 'text-center font-sequel text-h1   text-zembl-forest xs:text-h3 sm:text-h2 md:text-h1',
    'heading-1': 'text-center font-sequel text-h1   text-zembl-forest xs:text-h3 sm:text-h2 md:text-h1',
    'heading-2': 'text-center font-sequel text-h2   text-zembl-forest xs:text-h4 sm:text-h3 md:text-h2',
    'heading-3': 'xs:text-h5 font-sequel text-h3 text-zembl-forest sm:text-h4 md:text-h3',
    'heading-4': 'sm:text-h5 xs:text-h6 text-center font-sequel text-h4 md:text-h4',
    'heading-5': 'text-center font-sequel text-h4   text-zembl-forest',
    body: 'text-body xs:text-xs sm:text-sm md:text-body',
    'body-bold':
      'text-body-bold font-semibold text-zembl-forest xs:text-xs sm:text-sm md:text-body-bold',
    footer: 'text-footer text-zembl-forest xs:text-xs sm:text-sm md:text-footer',
    subtitle: 'text-subtitle font-bold text-zembl-ocean xs:text-xs sm:text-sm md:text-subtitle',
    'input-label': 'text-input-label md:text-input-label text-zembl-ocean xs:text-xs sm:text-sm',
    error: 'text-error xs:text-xs sm:text-sm md:text-error',
    tooltip: 'text-tooltip text-zembl-forest xs:text-xs sm:text-sm md:text-tooltip',
    'tooltip-link': 'text-tooltip text-zembl-forest underline xs:text-xs sm:text-sm md:text-tooltip',
    custom: '',
  };

  const Element: any = intent.startsWith('heading')
    ? `h${intent.split('-')[1]}`
    : intent === 'input-label'
    ? 'label'
    : intent === 'custom'
    ? 'span'
    : 'p';

  return <Element className={classNames(baseStyles[intent], className)}>{children}</Element>;
}

export const TextHighlight = ({ text, boldPhrases }: any) => {
  const boldText = (sourceText: any, wordsToBold: any) => {
    return wordsToBold.reduce((prev: any, curr: any) => {
      const regex = new RegExp(`(${curr})`, 'g');
      return prev.replace(regex, '**$1**'); // using markdown syntax for bold text
    }, sourceText);
  };

  const textToDisplay = boldText(text, boldPhrases);

  return <ReactMarkdown>{textToDisplay}</ReactMarkdown>;
};
