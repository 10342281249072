import { useState } from 'react';
import { ExistingCustomSignIn } from './components/existing-Custom-signin.component';
import { AccountVerificationCard } from './components/Cards';

export const ExistingCustomContainer = () => {
  const [showVerificationCard, setShowVerificationCard] = useState(false);

  const handleRegisterClick = () => {
    setShowVerificationCard(true);
  };

  if (showVerificationCard) {
    return <AccountVerificationCard />;
  }

  return <ExistingCustomSignIn onRegisterClick={handleRegisterClick} />;
};
