import React, { ButtonHTMLAttributes } from 'react';
import classNames from 'classnames';

export type ButtonIntent =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'accent'
  | 'accent-dark'
  | 'green'
  | 'billUpload'
  | 'bill-dark';

export type ButtonProps = {
  children: any;
  intent?: ButtonIntent;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export function Button({ children, intent = 'primary', className, ...props }: ButtonProps) {
  const defaultClasses = classNames(
    className,
    'rounded px-2 py-1  font-sequel  xs:px-4 xs:py-1 sm:px-6 sm:py-3',
    {
      'bg-zembl-teal text-white': intent === 'primary',
      'bg-zembl-ocean text-white': intent === 'secondary',
      'bg-zembl-forest text-white': intent === 'tertiary',
      'bg-zembl-violet text-white': intent === 'accent',
      'bg-zembl-violet text-zembl-forest': intent === 'accent-dark',
      'bg-zembl-green text-black text-xl font-med-bold shadow-2xl': intent === 'green',
      'bg-zembl-white h-12 w-5/6 flex justify-center items-center shadow-3xl text-zembl-green text-md font-bold ':
        intent === 'billUpload',
      'bg-black text-white   w-5/6  justify-center items-center shadow-3xl font-bold':
        intent === 'bill-dark',
    }
  );
  return (
    <button {...props} className={`${defaultClasses}`}>
      {children}
    </button>
  );
}
