import { useAuthStore } from 'domain/state';
import { DASHBOARD_ROUTE } from 'domain/common/constants';
import classNames from 'classnames';
import { navbar } from 'domain/common/constants/navbar.constants';
import { useState } from 'react';
import { Profile } from './components/profile.component';

export function Navbar() {
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
  const { logo, home, dashboard } = navbar;
  const links = isAuthenticated ? dashboard : home;

  const [isOpen, setIsOpen] = useState(false);

  return (
    <header
      className={classNames(
        'drop-shadow-navbar  relative z-50 flex h-navbar items-center justify-between self-stretch bg-zembl-forest px-2 py-1 text-zembl-white xs:px-7 xs:py-3'
      )}
    >
      {isAuthenticated ? (
        <>
          <a href={DASHBOARD_ROUTE.DASHBOARD}>
            <img className="h-logo" {...logo} />
          </a>
        </>
      ) : (
        <>
          <img className="h-logo" {...logo} />
        </>
      )}

      <nav className="flex items-center gap-0">
        {/* Desktop and large screens menu */}
        <ul className="hidden flex-wrap gap-7  sm:flex">
          {links.map((link) => (
            <li key={link.href} className="p-3 ">
              <a className="text-lg font-med-bold" {...link} />
            </li>
          ))}
        </ul>
        {isAuthenticated && <Profile />}
        {/* Small screens dropdown */}
        {!isAuthenticated && (
          <button
            className="bg-transparent p-3 sm:hidden"
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <path fill="currentColor" d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" />
            </svg>
          </button>
        )}
        <div
          className={`xs:mt-4\0 absolute top-navbar  z-50 sm:hidden lg:mt-70 ${
            isOpen ? 'block' : 'hidden'
          }`}
        >
          <ul className="flex flex-col gap-7 bg-zembl-forest p-4">
            {links.map((link) => (
              <li key={link.href}>
                <a {...link} />
              </li>
            ))}
          </ul>
        </div>
      </nav>
    </header>
  );
}
