import {
  randomAvatar,
  CompareYourBusiness,
  UnCoverRealSpending,
  SeeWhatZemblIsSaving,
} from 'domain/assets';
export const homepage = {
  mindMyBusiness: {
    heading: 'My Zembl',
    subheading: 'Sign in to access your Zembl services.',
    subheading2: 'Log in for insights online to improve your bottom line.',
  },
  whyMindMyBusiness: {
    heading: 'Why My Zembl?',
    cards: [
      {
        icon: { src: SeeWhatZemblIsSaving },
        title: 'See what Zembl is saving you.',
        description: 'See your current Zembl  services and potential ones, online, all the time.',
      },
      {
        icon: { src: UnCoverRealSpending },
        title: 'Uncover real spending insights',
        description:
          'Know what you’re spending across key cost centres with insights from your statement data.',
      },
      {
        icon: { src: CompareYourBusiness },
        title: 'Compare your business spending to others',
        description:
          'Get a read on what businesses like yours typically spend,  so you can take stock and take action.',
      },
    ],
  },
  testimonials: {
    heading: 'Testimonials',
    cards: [
      {
        stars: 4,
        description:
          'Slate helps you see how many more days you need to work to reach your financial goal for the month and year.',
        reviewer: {
          avatar: { src: randomAvatar, alt: 'Random Avatar' },
          name: 'Esther Webb',
          title: 'Designer',
        },
      },
      {
        stars: 4,
        description:
          'Slate helps you see how many more days you need to work to reach your financial goal for the month and year.',
        reviewer: {
          avatar: { src: randomAvatar, alt: 'Random Avatar' },
          name: 'Esther Webb',
          title: 'Designer',
        },
      },
      {
        stars: 4,
        description:
          'Slate helps you see how many more days you need to work to reach your financial goal for the month and year.',
        reviewer: {
          avatar: { src: randomAvatar, alt: 'Random Avatar' },
          name: 'Esther Webb',
          title: 'Designer',
        },
      },
    ],
  },
};
