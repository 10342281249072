import cn from 'classnames';
export type OfferContentCardsProps = {
  cards: { Icon: any; title: string; description: string }[];
  className?: any;
};
export function OfferContentCards({ cards, className }: OfferContentCardsProps) {
  return (
    <div className="flex flex-col items-center justify-center gap-24 xs:flex-col sm:flex-col md:flex-row">
      {cards.map(({ Icon, title, description }, index) => {
        return (
          <div
            key={`${title}-${index}`}
            className={cn(
              'text-1.75 leading-1.5 relative flex w-80 flex-col rounded-2xl border border-current bg-zembl-white p-4 pb-16 text-center font-sequel font-166 tracking-0.0525 text-zembl-forest',
              className
            )}
          >
            <div className="mb-6 text-2xl font-semibold">{title}</div>
            <p className="leading-1.5 flex-grow text-1.25 font-158 tracking-0.05 text-zembl-forest">
              {description}
            </p>
            <div className="absolute bottom-1.5 flex w-full  justify-start">
              <Icon className="h-12" />
            </div>
          </div>
        );
      })}
    </div>
  );
}
