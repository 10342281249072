import cn from 'classnames';
import { useLoginForm } from 'domain/hooks/use-login.hook';
import { login } from 'domain/common/constants/login.constants';
import { ControlledInput, Form, Button } from 'domain/common/components';

export const SignInForm = ({ onForgotPasswordClick }: any) => {
  const { heading, fields, forgotPassword, button } = login.loginForm;
  const { control, onSubmit, error } = useLoginForm(onForgotPasswordClick);
  const inputClass = cn('rounded border border-black h-10 ');

  return (
    <Form
      className="flex h-110 w-120 flex-col items-center justify-center rounded-2xl xs:px-32 lg:px-2"
      onSubmit={onSubmit}
      error={error}
    >
      <h2 className="mb-8 text-center text-3xl">{heading}</h2>
      {Object.values(fields).map((field: any) => (
        <ControlledInput
          className="mb-4  w-full"
          inputClassName={inputClass}
          key={field.name}
          control={control}
          placeholder={field.label}
          {...field}
        />
      ))}
      <Button
        intent="green"
        type="submit"
        className="flex w-full   items-center justify-center  rounded  font-bold text-black drop-shadow-xl "
      >
        {button}
      </Button>
      <div className="mt-4 text-center text-xl  ">
        <a
          className="text text-violet-400 underline underline-offset-4"
          href="#/login"
          onClick={onForgotPasswordClick}
        >
          {forgotPassword}
        </a>
      </div>
    </Form>
  );
};
