import { useLocation } from 'react-router-dom';
import { homepage } from 'domain/common/constants';

export function HeroSection() {
  const { mindMyBusiness } = homepage;
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  const hasToken = queryParams.has('token');
  return (
    <section className="flex flex-col items-center  xs:px-10 lg:mt-16 lg:px-1 ">
      <div className="home-header mb-4 xs:mb-6	 xs:text-3xl sm:mb-8   sm:text-3xl  md:mb-4 lg:mb-16 lg:text-8.5xl">
        {mindMyBusiness.heading}
      </div>
      <div className="home-header mb-3   font-sequel  xs:mb-4  xs:text-xl sm:mb-5  sm:text-xl md:mb-6 lg:text-3xl">
        {hasToken ? mindMyBusiness.subheading : mindMyBusiness.subheading2}
      </div>
    </section>
  );
}
