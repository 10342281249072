import { DASHBOARD_OFFER_ROUTE, DASHBOARD_ROUTE, HOME_ROUTE } from 'domain/common/constants';
import { useAuthStore } from 'domain/state';
import { Dashboard } from 'domain/pages/dashboard';
import { HomePage } from 'domain/pages/homepage';
import { AccountSettings } from 'domain/pages/account-settings';
import { SecuritySettings } from 'domain/pages/security-settings';

import { ContactUs } from 'domain/pages/contactus/like-to-learn-more.container';
import { BusinessLoan, Offers, SolarEnergy } from 'domain/pages/offers';
import { createBrowserRouter, RouterProvider, Navigate } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import './App.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const queryClient = new QueryClient();

const commonRoutes = [
  {
    path: HOME_ROUTE.HOME,
    element: <HomePage />,
  },
  {
    path: HOME_ROUTE.HOME_VERIFY_EMAIL,
    element: <HomePage />,
  },
  {
    path: HOME_ROUTE.INVITE,
    element: <HomePage />,
  },
  {
    path: '*',
    element: <Navigate to={HOME_ROUTE.HOME} />,
  },
];

const authRoutes = [
  {
    path: HOME_ROUTE.HOME,
    element: <Dashboard />,
  },
  {
    path: DASHBOARD_ROUTE.OFFERS,
    element: <Offers />,
  },
  {
    path: DASHBOARD_OFFER_ROUTE.SOLAR_ENERGY,
    element: <SolarEnergy />,
  },
  {
    path: DASHBOARD_OFFER_ROUTE.BUSINESS_LOAN,
    element: <BusinessLoan />,
  },
  {
    path: DASHBOARD_ROUTE.DASHBOARD,
    element: <Dashboard />,
  },
  {
    path: DASHBOARD_ROUTE.ACCOUNT_SETTINGS,
    element: <AccountSettings />,
  },
  {
    path: DASHBOARD_ROUTE.SECURITY_SETTINGS,
    element: <SecuritySettings />,
  },
  {
    path: DASHBOARD_ROUTE.CONTACT,
    element: <ContactUs />,
  },
  {
    path: DASHBOARD_OFFER_ROUTE.SOLAR_ENERGY,
    element: <SolarEnergy />,
  },
  {
    path: DASHBOARD_OFFER_ROUTE.BUSINESS_LOAN,
    element: <BusinessLoan />,
  },
  {
    path: '*',
    element: <Navigate to={HOME_ROUTE.HOME} />,
  },
];

const homeRouter = createBrowserRouter(commonRoutes);
const dashboardRouter = createBrowserRouter(authRoutes);

function App() {
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);

  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={isAuthenticated ? dashboardRouter : homeRouter} />
    </QueryClientProvider>
  );
}

export default App;
