import { zodResolver } from '@hookform/resolvers/zod';
import { useToggle } from 'domain/hooks';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { contactUsFormSchema } from './contact-us.schema';

export function useContactUs() {
  const [isMessageSubmitted, toggleMessageSubmitted] = useToggle(false);

  const { control, handleSubmit } = useForm({
    defaultValues: {
      firstName: 'John',
      surname: 'Smith',
      email: 'john@email.com',
      phone: '0412 345 678',
      interests: [],
      message: '',
    },
    resolver: zodResolver(contactUsFormSchema),
    mode: 'onBlur',
  });

  // @TODO: Contact us form api integration
  const [error] = useState('');
  const handleContactUs = handleSubmit(() => {
    toggleMessageSubmitted();
    return;
  });

  return { isMessageSubmitted, toggleMessageSubmitted, control, onSubmit: handleContactUs, error };
}
