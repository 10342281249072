import { Card, Button } from 'domain/common/components';

export const PasswordResetVerifiedEmailCard = () => (
  <Card title="Great!" message="Your password has been reset." iconPosition="top">
    <Button
      intent="accent-dark"
      type="submit"
      className="mt-8 w-full  rounded-2xl px-4 py-2 text-3xl   font-semibold  subpixel-antialiased  drop-shadow-lg xs:text-xl   md:text-xl "
    >
      Log in
    </Button>
  </Card>
);
