import { Card } from 'domain/common/components';
import { XIcon } from 'domain/assets';
import { useResetPasswordMutation, useVerifyEmailQuery } from 'domain/queries';
import { useAuthStore } from 'domain/state';

export const LinkExpiredCard = () => {
  const email = useAuthStore((state) => state.user?.email);
  const { mutate: verifyEmail } = useVerifyEmailQuery();

  const handleResendVerificationClick = () => {
    verifyEmail({ email });
  };

  return (
    <Card>
      <p className="mt-6 justify-center px-10 text-center text-xl">
        <div className="flex items-center justify-center">
          <XIcon fill="red" color="white" />
          <span className="ml-2">Link expired</span>
        </div>
        <div
          id="email-invite-expired"
          className="cursor-pointer underline hover:text-blue-500 active:text-blue-500"
          onClick={handleResendVerificationClick}
        >
          Click here to resend verification email
        </div>
      </p>
    </Card>
  );
};

export const ResetPasswordExpiredCard = () => {
  const email = useAuthStore((state) => state.user?.email);
  const { mutate: mutateResetPassword } = useResetPasswordMutation();

  const handleResendVerificationClick = () => {
    mutateResetPassword({ email });
  };

  return (
    <Card>
      <p className="mt-6 justify-center px-10 text-center text-xl">
        <div className="flex items-center justify-center">
          <XIcon fill="red" color="white" />
          <span className="ml-2">Link expired</span>
        </div>
        <div
          id="reset-password-invite-expired"
          className="cursor-pointer underline hover:text-blue-500 active:text-blue-500"
          onClick={handleResendVerificationClick}
        >
          Click here to resend verification email
        </div>
      </p>
    </Card>
  );
};
