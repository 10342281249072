import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  AccountVerificationCard,
  LinkExpiredCard,
  ResetPasswordExpiredCard,
  PasswordResetVerifiedEmailCard,
  ResentCard,
  ResetPasswordVerificationCard,
  VerifiedEmailCard,
  ForgotPassword,
  SignInForm,
  ResetPassword,
} from './components/Cards';
import { useTokenFromUrl } from 'domain/hooks';
import { useVerificationStore } from 'domain/state';

const useQueryParams = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const params = {
    isVerifiedCustomer: queryParams.has('verify_token'),
    hasVerified: queryParams.has('verified'),
    hasExpired: queryParams.has('expired'),
    hasResent: queryParams.has('resent'),
    hasVerify: queryParams.has('verify'),
    hasResetPassword: queryParams.has('resetPassword'),
  };

  return params;
};

export const SignInContainer = () => {
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [showResetPasswordVerification, setShowResetPasswordVerification] = useState(false);
  const { hasVerified, hasResent, hasVerify, hasResetPassword } = useQueryParams();
  const { verificationSuccessful } = useTokenFromUrl();
  const { verificationFailed, resetPasswordFailed } = useVerificationStore();

  const handleForgotPasswordClick = (event: any) => {
    event.preventDefault();
    setShowForgotPassword(true);
  };

  const handleResetPasswordClick = () => {
    setShowResetPasswordVerification(true);
  };

  if (verificationSuccessful) {
    return <VerifiedEmailCard />;
  }
  if (verificationFailed) {
    return <LinkExpiredCard />;
  }
  if (resetPasswordFailed) {
    return <ResetPasswordExpiredCard />;
  }
  if (hasVerified) {
    return <PasswordResetVerifiedEmailCard />;
  }
  if (hasResent) {
    return <ResentCard />;
  }
  if (hasVerify) {
    return <AccountVerificationCard />;
  } else if (hasResetPassword) {
    return <ResetPassword />;
  }
  if (showResetPasswordVerification) {
    return <ResetPasswordVerificationCard />;
  }
  if (showForgotPassword) {
    return <ForgotPassword onResetPasswordClick={handleResetPasswordClick} />;
  }

  return <SignInForm onForgotPasswordClick={handleForgotPasswordClick} />;
};
